import { Link } from "react-router-dom";
import { ReactNode } from "react";

import { Plan as PlanType, PlanPrice } from "../../../requests/plans/types";
import { ActiveSubscription } from "../../../requests/subscriptions/types";
import { subscriptionPaths } from "../../../routes/subscriptions/paths";
import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import { consolePaths } from "../../../routes/_console/paths";
import { currencySymbol, notNull } from "../../../utils/strings";
import { decimalPrice } from "../../../utils/numbers";
import { useAppState } from "../../../redux/state";
import Styles from "./styles";

type Props = {
  plan: PlanType;
  planPrice: PlanPrice;
  activeSubscription: ActiveSubscription | null;
  action: "purchase" | "none" | ReactNode;
  discountedPrice?: number;
};

function PlanDetail({
  plan,
  planPrice,
  activeSubscription,
  action,
  discountedPrice,
}: Props) {
  const { user: loggedInUser } = useAppState();

  return (
    <Styles className={`plan flex column ${plan.planType}`}>
      <div className="head">
        {/* Plan Name and Price */}
        <div className="name">{plan.name}</div>
        <div className="price flex">
          {currencySymbol(planPrice.currency)}
          {decimalPrice(
            notNull(discountedPrice) ? discountedPrice : planPrice.amount
          )}
          <div className="interval">{`/ ${planPrice.interval}`}</div>
        </div>
      </div>
      {/* Plan Description */}
      <div className="content">
        <div className="title">{plan.featureTitle}</div>
        <ul className="features flex column">
          {plan.planFeatures.map((feature) => (
            <li className="feature" key={feature.id}>
              {feature.description}
            </li>
          ))}
        </ul>
      </div>
      {action === "purchase" ? (
        loggedInUser &&
        activeSubscription &&
        activeSubscription.planPriceId === planPrice.id ? (
          <Link
            className="button full-width submit white-black choose-plan"
            to={
              loggedInUser.completedOnboarding
                ? consolePaths.SUBSCRIPTION
                : profileSetupPaths.NAME_ENTRY
            }
          >
            Subscribed
          </Link>
        ) : (
          <Link
            className="button full-width submit choose-plan"
            to={subscriptionPaths.checkout(planPrice.id)}
          >
            Choose Plan
          </Link>
        )
      ) : action === "none" ? null : (
        <>{action}</>
      )}
    </Styles>
  );
}

export default PlanDetail;
