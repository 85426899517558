import { Dispatch, SetStateAction } from "react";

import FormSteps, { useFormNavigation } from "../../forms/FormSteps";
import { Quiz } from "../../../requests/missions/types";
import { FormStep } from "../../forms/FormSteps/types";
import { FormValues } from "../../forms/Form/types";
import { ProgressBar } from "../../forms";
import MissionQuiz from "../MissionQuiz";
import Styles from "./styles";



type Props = {
  quizzes: Quiz[];
  lessonLink: string;
  completionLink?: string;
  formValues: FormValues;
  setFormValues: Dispatch<SetStateAction<FormValues>>
  onSubmit: () => void;
}

function Mission({
  quizzes,
  lessonLink,
  formValues,
  setFormValues,
  onSubmit
}: Props) {
  const {
    currentStep, FormNavigation, nextStep, previousStep
  } = useFormNavigation();

  const formSteps: FormStep[] = quizzes.map(
    (quiz, index) => ({
      title: "",
      content: (
        <MissionQuiz
          FormNavigation={FormNavigation}
          previousStep={previousStep}
          nextStep={nextStep}
          quiz={quiz}
          quizIndex={index}
          quizzesLength={quizzes.length}
          values={formValues}
          setValues={setFormValues}
          onSubmit={onSubmit}
          lessonLink={lessonLink}
        />
      )
    })
  );


  return (
    <Styles className="flex column">
      <ProgressBar
        total={quizzes.length}
        value={currentStep}
        description={`Question ${currentStep + 1} of ${quizzes.length}`}
      />
      <FormSteps
        currentStep={currentStep}
        steps={formSteps}
        progressType="none"
      />
    </Styles>
  );
}

export default Mission;