import axios from "axios";

import { ErrorResponse } from "../../types";
import { PlanPrice } from "../plans/types";



/*  ############################ */
/** ## PLAN PRICE API REQUESTS # */
/*  ############################ */
const usePlanPrices = () => {
  /*************************/
  /** Get Plan Price by ID */
  const getPlanPrice = (planPriceId: number | string) => {
    return new Promise<PlanPrice>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/plan-prices/${planPriceId}`,
      }).then((res) => {
        const resData = res.data as PlanPrice;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    getPlanPrice
  }
}

export default usePlanPrices;