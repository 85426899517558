import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import { Address } from "../../../requests/addresses/types";
import useAddresses from "../../../requests/addresses";
import { User } from "../../../requests/users/types";
import { Loader } from "../../../components/layout";
import useUsers from "../../../requests/users";
import useAccountUtils from "./utils";
import Styles from "./styles";



function Account() {
  const { getMyAddress } = useAddresses();
  const { getUserProfile } = useUsers();
  const { userData, addressData } = useAccountUtils();
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User>();
  const [address, setAddress] = useState<Address>();


  const fetchData = async () => {
    setLoading(true);
    try {
      const userProfile = await getUserProfile();
      setUser(userProfile);
    } catch (error) {
      console.error(error);
    }
    try {
      const userAddress = await getMyAddress();
      setAddress(userAddress);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }


  useEffect(() => {
    fetchData();
  }, [])


  const renderUserContent = (key: keyof User) => {
    const value = userData[key];
    if (value?.content && user) {
      return value.content(user);
    }
    return user ? String(user[key]) : "";
  };


  const renderAddressContent = (key: keyof Address) => {
    const value = addressData[key];
    if (value?.content && address) {
      return value.content(address);
    }
    return address ? String(address[key]) : "";
  };


  return (
    <Styles className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
          <header>
            <div className="flex">
              <h1 className="title">
                Account Details
              </h1>
            </div>
          </header>
          <div className="content-wrapper">
            <div className="grid-row">
              {/* User Data */}
              <div className="data-block">
                <h3 className="title">
                  Profile
                </h3>
                <div className="psuedo-table">
                  {Object.entries(userData).map(([key, value]) => (
                    <div
                      key={key}
                      className="psuedo-table-data flex justify-between"
                    >
                      <div className="key">
                        {value.description}
                      </div>
                      <div className="value">
                        {renderUserContent(key as keyof User)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* Address Data */}
              <div className="data-block">
                <h3 className="title">
                  Address
                </h3>
                <div className="psuedo-table">
                  {Object.entries(addressData).map(([key, value]) => (
                    <div
                      key={key}
                      className="psuedo-table-data flex justify-between"
                    >
                      <div className="key">
                        {value.description}
                      </div>
                      <div className="value">
                        {renderAddressContent(key as keyof Address)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Link
              to={profileSetupPaths.NAME_ENTRY}
              className="button submit mlr-auto"
            >
              Edit My Account
            </Link>
          </div>
        </>
      )}
    </Styles>
  );
}

export default Account;