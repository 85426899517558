/*****************************************/
/** Get Root Path from a pathname string */
export const getRootPath = (pathname: string, originalRoot: string) => {
  const segments = pathname.split("/")
    .filter(segment => segment !== "");
  const originSegments = originalRoot.split("/")
    .filter(segment => segment !== "");
  if ((segments.length > 0) && originSegments.length > 0) {
    const rootSegment = segments[0];
    const originalSegment = originSegments[0]
    if (rootSegment !== originalSegment)
      return `/${rootSegment}`;
  }

  return "";
}