import { persistStore, persistReducer } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

import rootRootReducer, { RootState } from "./reducers";

export const TOKEN_STORAGE_KEY = "sona-accounts";

const persistConfig = {
  key: TOKEN_STORAGE_KEY,
  storage,
};

const persistedReducer = persistReducer<RootState>(
  persistConfig,
  rootRootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/PAUSE",
          "persist/PURGE",
          "persist/REGISTER",
        ],
      },
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
