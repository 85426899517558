import { nonAuthPaths } from "./paths";
import { RouteType } from "../types";
import {
  SignUp,
  SignIn,
  SignUpFamilyInvite,
  ForgotPassword,
  ResetPassword,
  AcceptFamilyInvite,
  CheckFamilyInvite,
  SetNewPassword,
  LoadToken,
} from "../../pages/_nonAuth";



/*****************************/
/** Non-Authenticated Routes */
export const nonAuthRoutes: RouteType[] = [
  {
    path: nonAuthPaths.SIGN_UP,
    element: <SignUp />
  },
  {
    path: nonAuthPaths.SIGN_IN,
    element: <SignIn />
  },
  {
    path: nonAuthPaths.LOAD_TOKEN,
    element: <LoadToken />
  },
  {
    path: nonAuthPaths.SIGN_UP_FAMILY_INVITE,
    element: <SignUpFamilyInvite />
  },
  {
    path: nonAuthPaths.FORGOT_PASSWORD,
    element: <ForgotPassword />
  },
  {
    path: nonAuthPaths.RESET_PASSWORD,
    element: <ResetPassword />
  },
  {
    path: nonAuthPaths.SET_NEW_PASSWORD,
    element: <SetNewPassword />
  },
  {
    path: nonAuthPaths.ACCEPT_INVITE,
    element: <AcceptFamilyInvite />
  },
  {
    path: nonAuthPaths.CHECK_INVITE,
    element: <CheckFamilyInvite />
  }
]