import { subscriptionPaths } from "../subscriptions/paths";
import { kycRoot } from "../profileSetup/paths";
import { familyPaths } from "../family/paths";



export const consoleRoot = "/manage";

/******************/
/** Console Paths */
export const consolePaths = {
  DASHBOARD: `${consoleRoot}/dashboard/`,
  ACCOUNT: `${consoleRoot}/account/`,
  FAMILY: familyPaths.detail(consoleRoot),
  CLAN: `${consoleRoot}/clan/`,
  SUBSCRIPTION: `${consoleRoot}/subscription/`,
  SUBSCRIPTION_OPTIONS: `${consoleRoot}/subscription/upgrade-options/`,
  MISSIONS: `${consoleRoot}/missions`,
  AFFIRMATIONS: `${consoleRoot}/affirmations`,
  WALLET: `${consoleRoot}/wallet`,
  STORE: `${consoleRoot}/store`,
  DOWNLOAD: `${consoleRoot}/download/`,
  SUPPORT: `${consoleRoot}/support`,
  VERIFICATION: `${kycRoot}/`
}

/***************************/
/** Console > Family Paths */
export const consoleFamilyPaths = {
  SETUP: familyPaths.setup(consoleRoot),
  DETAIL: familyPaths.detail(consoleRoot),
  ADD_USER: familyPaths.addUser(consoleRoot),
  EDIT_USER: `${consoleRoot}${familyPaths.EDIT_USER}`,
  CREATE_INVITE: familyPaths.createInvite(consoleRoot),
  VIEW_INVITES: familyPaths.viewInvites(consoleRoot)
}


export const consoleSubscriptionPaths = {
  PLAN_LIST: subscriptionPaths.planList(consoleRoot),
  CHECKOUT: subscriptionPaths.checkout(consoleRoot)
}