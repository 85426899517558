import styled from "styled-components";

import { colors, functions } from "../../../styles";



type Props = {
  color?: string;
}

const radius = "70px";

const Styles = styled.div<Props>`
  width: 100%;
  .bar {
    transition: all 300ms ease-in-out;
    background-color: ${(props: Props) => (
    props.color
      ? functions.rgba(props.color, 0.2)
      : functions.rgba(colors.primary, 0.2))};
    border-radius: ${radius};
    overflow: hidden;
    width: 100%;
    height: 8px;
    .fill {
      transition: all 300ms ease-in-out;
      width: 10%;
      height: 100%;
      border-radius: ${radius};
      background-color: ${(props: Props) => (
    props.color
      ? props.color
      : colors.primary)};
    }
  }
  .description {
    margin-top: 1rem;
    font-size: 1.1rem;
    color: ${colors.grey6};
  }
`;

export default Styles;