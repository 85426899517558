import { getRootPath } from "../utils";



export const subscriptionRoot = "/subscriptions";
const planRoot = `${subscriptionRoot}/plans`;

/***********************/
/** Subscription Paths */
export const subscriptionPaths = {
  // Subscription
  SUCCESS: `${subscriptionRoot}/success/`,
  FAILED: `${subscriptionRoot}/failed/`,

  // Plans
  PLAN_LIST: `${planRoot}`,
  planList: (pathname: string = "") =>
    `${getRootPath(pathname, subscriptionRoot)
    }${planRoot}`,
  CHECKOUT: `${planRoot}/pricing/:planPriceId`,
  checkout: (
    planPriceId: number | string,
    pathname: string = ""
  ) => (
    `${getRootPath(pathname, subscriptionRoot)
    }${planRoot}/pricing/${planPriceId}`
  ),
  VOUCHER_ENTRY: `${planRoot}/voucher-entry`
}