import { useNavigate } from "react-router-dom";
import { useState, MouseEvent } from "react";


import { useAppModal } from "../../../components/layout/AppModal/utils";
import ananse_smile from
  "../../../assets/vector-images/ananse/bust_smile_staff_no glow.svg";

import { UserMission } from "../../../requests/missions/types";
import { missionPaths } from "../../../routes/missions/paths";
import { User } from "../../../requests/users/types";
import { LessonModal } from "./styles";



const postLessonMsgs = [
  "That was fun!\nReady for the Quiz? 🎉",
  "What a great video!\nLet's see what you remember. 😀📚",
  "Awesome lesson!\nAre you ready for the quiz? 📝",
  "Great job watching!\nTime for a fun quiz! 😊🎓",
  "That was a cool video!\nLet's see what you've learned! 🌟",
  "Nice work!\nLet's test your knowledge with a quiz! 💡",
  "That video was exciting!\nReady to show what you remember? 🚀",
  "You did great watching that!\nLet's get quizzing! 👍",
  "That was Fantastic!\nAre you excited for the quiz? 😄",
  "Well done on watching!\nNow, let's dive into the quiz! 🌊",
  "Enjoyed the video?\nLet's see how much you've learned with a quiz! 🤔",
  "Nice job!\nLet's see how much you can recall with a quiz! 🧠",
  "Awesome!\nLet's see how much you've learned with a quiz! 🏆",
  "You've seen the video!\nNow it's time for a fun quiz! 🎠",
  "Great work on watching!\nReady to show what you've learned? ✨",
  "Well done!\nLet's put your knowledge to the test with a quiz! 🏅",
  "Fantastic!\nLet's see how much you can remember with the quiz! 🎊"
];



const useLessonUtils = (
  user: User,
  desertId?: string | number,
  userMission?: UserMission
) => {
  const navigate = useNavigate();
  const { setAppModal, closeModal } = useAppModal();
  const [playing, setPlaying] = useState<boolean>(false);


  const getPostLessonMessage = () => {
    return postLessonMsgs[
      Math.floor(Math.random() * postLessonMsgs.length)
    ];
  }

  const watchAgain = (e?: MouseEvent) => {
    if (e) e.preventDefault();
    closeModal();
    setPlaying(true);
  }


  /***********************/
  /** Show Intro Message */
  const onStart = () => {
    setAppModal({
      title: "",
      children: (
        <LessonModal className="flex column">
          <div className="img-wrapper">
            <img src={ananse_smile} alt="" />
          </div>
          <figcaption className="flex column">
            <div className="title bold">
              Hello {user.firstName ?? "Buddy"},
            </div>
            <div className="description">
              Let's continue our quest!
              <br />
              You'll need to watch this entire{" "
              }mission and take the quizzes to earn{" "
              }<span className="bold">up to 10 tokens</span>{" "
              }for this mission.
              <br /><br />
              Ready?
            </div>
          </figcaption>
        </LessonModal>
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button full-width"
            onClick={() => {
              closeModal();
              setPlaying(true);
            }}
          >
            Let's do this!
          </button>
        </div>
      ),
      cancelCallback: (
        () => {
          setPlaying(true);
        }
      )
    });
  }


  /*******************************/
  /** Show End of Lesson Message */
  const onEnded = () => {
    if (!userMission || !desertId) return;
    if (document.fullscreenElement) {
      document.exitFullscreen()
        .catch((err) => console.error(err));
    }
    const message = getPostLessonMessage().split("\n");
    const [title, description] = message;
    setAppModal({
      title: "",
      children: (
        <LessonModal className="flex column">
          <div className="img-wrapper">
            <img src={ananse_smile} alt="" />
          </div>
          <figcaption className="flex column">
            <div className="title bold">
              {title}
            </div>
            <div className="description">
              {description}
            </div>
          </figcaption>
        </LessonModal>
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button full-width white-black"
            onClick={watchAgain}
          >
            Watch Again
          </button>
          <button
            className="button full-width "
            onClick={() => {
              closeModal();
              navigate(missionPaths.missionQuiz(
                desertId,
                userMission.id
              ));
            }}
          >
            Take Quiz
          </button>
        </div>
      ),
      cancelCallback: watchAgain
    });
  }


  return {
    onStart,
    onEnded,
    playing,
  }
}

export default useLessonUtils;