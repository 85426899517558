import styled from "styled-components";

import {
  snippets, properties, functions, colors, resolutions
} from "../../../styles";



const Styles = styled.div`
  #Result {
    header {
      gap: 1.5rem;
      img {
        width: 60px;
      }
      .title {
        font-size: 2rem;
        text-align: center;
        @media(max-width: ${resolutions.tablet}) {
          font-size: 5vw;
        }
      }
    }
    section {
      ${snippets.flexCenter}
      .panel {
        width: 100%;
        max-width: ${properties.maxFieldWidth};
        img {
          height: 200px;
        }
        .message-box {
          padding: 2rem;
          border-radius: 15px;
          text-align: center;
          width: 100%;
          font-size: 1.25rem;
          font-weight: 600;
          color: ${colors.grey13};
          border: 2px solid ${colors.primary};
          &.passed {
            background-color: ${functions.rgba(colors.primary, 0.1)};
          }
          &.failed {
            background-color: ${functions.rgba(colors.error, 0.1)};
            border-color: ${colors.error};
          }
        }
      }
    }
  }
`;

export default Styles;