import styled from "styled-components";

import { colors, functions, resolutions } from "../../../styles";



const Styles = styled.div`
  flex: 1;
  header {
    column-gap: 2rem;
    row-gap: 1rem;
    .heading {
      margin-bottom: 0;
    }
    .toggle-field {
      label {
        border: 1px solid ${colors.primary};
        border-radius: 50px;
        overflow: hidden;
        .flex {
          color: ${colors.grey3};
          position: relative;
          width: 200px;
          .option {
            flex-basis: 50%;
            padding: 7px 17px;
            font-weight: 700;
            color: ${colors.primary};
            transition: color 300ms ease-in-out;
            text-align: center;
            &.active {
              color: ${colors.white};
            }
          }
        }
        .slider {
          position: absolute;
          top: 0;
          right: 0;
          background-color: ${colors.primary};
          width: 50%;
          height: 100%;
          border-radius: 50px;
          transition: transform 300ms ease-in-out;
          &.active {
            transform: translateX(-100%);
          }
        }
      }
      input {
        &:focus {
          & ~ label {
            border-color: ${colors.primary} !important;
          }
        }
      }
    }
  }
  .content-wrapper {
    border: 7px solid ${functions.rgba(colors.primary, 0.1)};
    border-radius: 20px;
    background-color: ${colors.white};
    padding: 2rem;
    width: auto;
    @media(max-width: ${resolutions.tabletLarge}) {
      border: none;
      padding: 1rem;
    }
    @media(max-width: ${resolutions.tablet}) {
      padding: 0;
    }
    .plans {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
      justify-content: center;
    }

    ${'' /* Free Trial Text */}
    .free-trial {
      margin-top: 2rem;
      text-align: center;
      color: ${colors.grey6};
    }
  }
`;

export default Styles;