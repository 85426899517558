import styled from "styled-components";



const Styles = styled.div`
  .page-wrapper {
    /* align-items: center; */
  }
`;

export default Styles;