import { useState, useEffect, SetStateAction, Dispatch } from "react";

import { useInput } from "../../../utils/forms";
import TextField from "../TextField";
import Styles from "./styles";
import moment from "moment";



type Props = {
  value: Date | string;
  setValue: Dispatch<SetStateAction<Date | string>>;
  validDob: boolean;
  setValidDob: Dispatch<SetStateAction<boolean>>;
}
function DobField({
  value,
  setValue,
  setValidDob
}: Props) {
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [displayValue, setDisplayValue] = useState<string>("");
  const {
    value: day,
    setValue: setDay,
    numChange: dayCh
  } = useInput({
    initialValue: ""
  });
  const {
    value: month,
    setValue: setMonth,
    numChange: monthCh
  } = useInput({
    initialValue: ""
  });
  const {
    value: year,
    setValue: setYear,
    numChange: yearCh
  } = useInput({
    initialValue: ""
  });


  useEffect(() => {
    // Whenever value changes, propagate the change up
    if (!day || !month || !year) {
      setValue("");
    } else if (day && month && year) {
      if (year.toString().length === 4)
        setValue(`${year}-${month}-${day}`);
    }
  }, [day, month, year]);

  useEffect(() => {
    if (!value) return;
    if (moment(value).isValid()) {
      setValidDob(true);
      setDisplayValue(moment(value).format("Do MMMM, YYYY"))
    } else {
      setValidDob(false);
      setDisplayValue("Please enter a valid date")
    }
  }, [value])

  useEffect(() => {
    if (!value) return;
    if (firstRender) {
      setDay(moment(value).format("DD"));
      setMonth(moment(value).format("MM"));
      setYear(moment(value).format("YYYY"));
      setFirstRender(false);
    }
  }, [value])



  return (
    <Styles className="dob-field">
      <div className="values-wrapper">
        <TextField
          value={day}
          onChange={dayCh}
          placeholder="Day"
          maxLength={2}
        />
        <TextField
          value={month}
          onChange={monthCh}
          placeholder="Month"
          maxLength={2}
        />
        <TextField
          value={year}
          onChange={yearCh}
          placeholder="Year"
          maxLength={4}
        />
      </div>
      <div className="selected-dob">
        {displayValue}
      </div>
    </Styles>
  );
}

export default DobField;
