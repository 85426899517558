import { Link } from "react-router-dom";
import { useEffect } from "react";

import { subscriptionPaths } from "../../../routes/subscriptions/paths";
import filter from "../../../assets/vector-images/generic/filter.svg";
import { LabelTooltip, SelectField } from "../../../components/forms";
import { Loader, PlanDetail } from "../../../components/layout";
import { useAppState } from "../../../redux/state";
import useSubscriptionListUtils from "./utils";
import Styles from "./styles";



function PlanList() {
  const { user } = useAppState();
  const {
    IntervalOptions, activeSubscription, loading,
    interval, plans, intervalValue, firstRender,
    getPlans, reloadPlanList, updateInterval, setLoading
  } = useSubscriptionListUtils(user);


  /***************/
  /** List Plans */
  const getPlanList = async () => {
    if (firstRender) return;
    try {
      setLoading(true);
      await reloadPlanList()
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    getPlanList();
  }, [interval])

  useEffect(() => {
    getPlans();
  }, [])



  return (
    <Styles className="root-content">
      <div className="page-wrapper">
        {(loading) ? (
          <Loader />
        ) : (
          <>
            {/* Voucher Entry */}
            <div
              className="onboarding-prompt flex wrap align-center justify-between"
            >
              <p className="message">
                Have a voucher code?
              </p>
              <Link
                to={subscriptionPaths.VOUCHER_ENTRY}
                className="button sm"
              >
                Enter it Here
              </Link>
            </div>
            {/* Heading */}
            <header className="flex justify-between align-center wrap">
              <div className="heading">
                <h3 className="title">
                  Select Subscription Plan
                </h3>
                <div className="description">
                  Choose a subscription to continue with SONA
                </div>
              </div>
              <div className="field-column">
                <LabelTooltip htmlFor="interval">
                  Billing Cycle
                </LabelTooltip>
                <SelectField
                  name="interval"
                  icon={filter}
                  showSearch={false}
                  options={IntervalOptions}
                  value={intervalValue}
                  onChange={updateInterval}
                  placeholder="Billing Cycle"
                />
              </div>
            </header>

            {/* Available Plans */}
            <section className="content-wrapper">
              <div className="plans">
                {plans.map((plan) => (
                  <PlanDetail
                    plan={plan}
                    planPrice={plan.planPrices[0]}
                    activeSubscription={activeSubscription}
                    action="purchase"
                  />
                ))}
              </div>
              {user ? (
                <div className="free-trial">
                  7-day free trial.
                  You can cancel or upgrade your plan anytime!
                </div>
              ) : (
                <div className="free-trial">
                  Get a 7-day free trial when you sign up.
                  You can cancel or upgrade your plan anytime!
                </div>
              )}
            </section>
          </>
        )}
      </div>
    </Styles>
  );
}

export default PlanList;