import { Outlet } from "react-router-dom";
import axios from "axios";

import { useAppModal } from "../../layout/AppModal/utils";
import { useAppState } from "../../../redux/state";
import { ResponseCodes } from "../../../types";
import { RootPage } from "..";
import Styles from "./styles";



function MixedRoute() {
  const { user, token } = useAppState();
  const { setAppModal } = useAppModal();
  /***************************************************************/
  /** Append these headers to authenticate all outgoing requests */
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Token ${token}`;
  }

  /* ALL AUTHENTICATED NETWORK RESPONSES */
  axios.interceptors.response.use(
    (response: any) => response,
    (error) => {
      if (error && error.response) {
        const { status } = error.response;
        if (status === ResponseCodes.Forbidden) {
          setAppModal({
            title: "There's an Issue",
            children: (
              "Unfortunately you are not authorized to view this page"
            )
          });
        }
      }
      return Promise.reject(error);
    }
  );


  return (
    <RootPage>
      <Styles className="root-page">
        <Outlet />
      </Styles>
    </RootPage>
  );
}

export default MixedRoute;