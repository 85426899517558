import {
  useState, useRef, FormEvent, MouseEvent,
  Dispatch, SetStateAction
} from "react";

import { FormField, FormValues } from "../../../components/forms/Form/types";
import { PostcoderResult } from "../../../requests/addresses/types";
import useAddresses from "../../../requests/addresses";
import { Spinner } from "../../../components/layout";
import { Form } from "../../../components/forms";
import { AddressFieldsType } from "./utils";



type Props = {
  setFieldsType: Dispatch<SetStateAction<AddressFieldsType>>;
  setAddressValues: Dispatch<SetStateAction<FormValues>>
}

function AutoAddress({
  setFieldsType,
  setAddressValues
}: Props) {
  const { findAddress } = useAddresses();
  const formRef = useRef<HTMLFormElement>(null);
  const [searchResults, setSearchResults] = useState<PostcoderResult[]>([]);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [searching, setSearching] = useState<boolean>(false);


  /*******************************/
  /** Find address via Postcoder */
  const searchForAddress = (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    setSearching(!searching);
    findAddress(formValues.search)
      .then((results) => {
        setSearchResults(results);
      }).catch(() => {
        setSearchResults([]);
      }).finally(() => {
        setSearching(false);
      });
  }

  /**************************************/
  /** Set Address from Postcoder Result */
  const setAddressFromResult = (
    e: MouseEvent,
    result: PostcoderResult
  ) => {
    e.preventDefault();
    setAddressValues((prevState) => ({
      ...prevState,
      addressLine1: `${result.premise || ""} ${result.street || ""}`.trim(),
      houseNumber: result.number || "",
      houseName: result.buildingname || "",
      flat: result.subbuildingname || "",
      street: result.street || "",
      postCode: result.postcode || "",
      county: result.county || "",
      city: result.posttown || ""
    }));
    setFieldsType("manual");
  }

  /**************************/
  /** Address Finder fields */
  const formFields: FormField[] = [
    {
      type: "text",
      name: "search",
      label: "Find an Address",
      placeholder: "E.g. '36 Factory Lane' or 'CR0 3RL'",
      groupClass: "field-group 1"
    }
  ]



  return (
    <Form
      fields={formFields}
      values={formValues}
      setValues={setFormValues}
      onSubmit={searchForAddress}
      ref={formRef}
    >
      {searching ? (
        <div className="flex justify-between align-center searching">
          <div className="grey-text">
            Finding your address ...
          </div>
          <Spinner />
        </div>
      ) : searchResults.length ? (
        <ul className="flex column search-results">
          {searchResults.map((result, index) =>
            <li key={`${result.summaryline}-${index}`}>
              <button
                type="button"
                className="result"
                onClick={(e) => setAddressFromResult(e, result)}
              >
                {result.summaryline}
              </button>
            </li>
          )}
        </ul>
      ) : null}
      <div className="flex column address-mode">
        <div className="field-info">
          Cannot find your address?
        </div>
        <button
          type="button"
          className="link"
          onClick={(e) => {
            e.preventDefault();
            setFieldsType("manual");
          }}
        >
          Enter address manually
        </button>
      </div>
      <button
        type="submit"
        className="button submit mlr-auto"
      >
        Find Address
      </button>
    </Form>
  );
}

export default AutoAddress;