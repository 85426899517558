import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useRef } from "react";
import { getName } from "country-list";

import { firstTwoWords, normalizeCountryCode } from "../../../utils/strings";
import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import { UpdateProfileArgs } from "../../../requests/users/types";
import Form from "../../../components/forms/Form";
import useUsers from "../../../requests/users";
import useAddressEntryUtils from "./utils";
import { ProfileSetupContext } from "..";
import AutoAddress from "./AutoAddress";
import Styles from "./styles";



function AddressEntry() {
  const navigate = useNavigate();
  const { updateProfile } = useUsers();
  const formRef = useRef<HTMLFormElement>(null);
  const { address } = useOutletContext<ProfileSetupContext>();
  const {
    formFields, fieldsType, setFieldsType,
    addressValues, setAddressValues
  } = useAddressEntryUtils();


  /*****************/
  /** Save Address */
  const saveData = () => {
    const { country, ...otherAddressValues } = addressValues;
    const profileData: UpdateProfileArgs = {
      address: otherAddressValues
    }
    updateProfile(profileData)
      .then(() => {
        navigate(profileSetupPaths.PROFILE_SUMMARY);
      }).catch(() => null);
  }


  useEffect(() => {
    if (!address) return;
    const {
      addressLine1, city, county, postCode,
      houseNumber, houseName, street, flat,
      countryCode, phoneNumber, country
    } = address;
    if (addressLine1) {
      setFieldsType("manual");
    } else {
      setFieldsType("auto");
    }
    setAddressValues((prevState) => ({
      ...prevState,
      addressLine1,
      houseNumber,
      houseName,
      street,
      city,
      postCode,
      flat,
      countryCode: normalizeCountryCode(countryCode),
      phoneNumber,
      county,
      country: firstTwoWords(getName(country || ""))
    }));
  }, [address])



  return (
    <Styles className="page-wrapper">
      {/* Heading */}
      <header>
        <h3 className="title">
          What's your current address?
        </h3>
      </header>
      {fieldsType === "auto" ? (
        // Address Finder
        <AutoAddress
          setFieldsType={setFieldsType}
          setAddressValues={setAddressValues}
        />
      ) : (
        // Manual Address Entry
        <Form
          fields={formFields}
          values={addressValues}
          setValues={setAddressValues}
          onSubmit={saveData}
          ref={formRef}
        >
          <button
            type="submit"
            className="button submit mlr-auto"
          >
            Save Details
          </button>
        </Form>
      )}
    </Styles>
  );
}

export default AddressEntry;