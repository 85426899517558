import { useState } from "react";

import { hasActiveSubscription } from "../../../requests/subscriptions/utils";
import { ActiveSubscription } from "../../../requests/subscriptions/types";
import { SelectFieldOption } from "../../../components/forms/SelectField";
import useSubscriptions from "../../../requests/subscriptions";
import { useQueryParams } from "../../../utils/components";
import useAddresses from "../../../requests/addresses";
import { Plan } from "../../../requests/plans/types";
import { User } from "../../../requests/users/types";
import { useInput } from "../../../utils/forms";
import usePlans from "../../../requests/plans";



export const IntervalOptions: SelectFieldOption[] = [
  {
    value: "month",
    label: "Monthly"
  },
  {
    value: "year",
    label: "Yearly"
  }
]

const useSubscriptionListUtils = (user?: User | null) => {
  const { getMyAddress } = useAddresses();
  const { getMySubscription } = useSubscriptions();
  const { queryParams, updateFilters } = useQueryParams();
  const interval = queryParams.get("interval") || "year";
  const [country, setCountry] = useState<string>("GB");
  const { listPlans } = usePlans();
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeSubscription, setActiveSubscription] = useState<
    ActiveSubscription | null
  >(null);
  const [plans, setPlans] = useState<Plan[]>([]);
  const {
    value: intervalValue,
    setValue: setIntervalValue
  } = useInput({
    initialValue: interval || "year",
    selectValues: IntervalOptions.map(option => option.value)
  });


  const updateInterval = (value: string) => {
    setIntervalValue(value);
    updateFilters("interval", value);
  }

  /***************/
  /** List Plans */
  const reloadPlanList = (cty: string = country) => {
    return new Promise<void>((resolve) => {
      const params: Record<string, string> = {
        interval,
        country: cty
      }
      listPlans(params)
        .then((resData: Plan[]) => {
          setPlans(resData);
        }).catch(() => null)
      resolve();
    });
  }


  const getPlans = async () => {
    // Anonymous User
    if (!user) {
      try {
        await reloadPlanList();
      } catch { }
      finally {
        setFirstRender(false);
        setLoading(false);
      }
      return;
    }

    // Logged-in User
    try {
      setLoading(true)
      const address = await getMyAddress();
      const subscription = await getMySubscription();
      let country = (address && address.country)
        ? address.country
        : "GB";
      const activeSubscription = hasActiveSubscription(subscription);
      if (activeSubscription) {
        country = activeSubscription.planPrice.plan.region;
        setActiveSubscription(activeSubscription);
      }
      await reloadPlanList(country);
      setCountry(country);
    }
    catch { }
    finally {
      setLoading(false);
      setFirstRender(false);
    }
  }


  return {
    IntervalOptions,
    activeSubscription,
    firstRender,
    interval,
    intervalValue,
    loading,
    user,
    plans,
    getPlans,
    setLoading,
    reloadPlanList,
    updateInterval
  }
}

export default useSubscriptionListUtils;