import axios from "axios";

import { ErrorResponse, PaginatedResponse } from "../../types";
import useAppActions from "../../redux/actions/app";
import { User } from "../users/types";
import {
  CreateFamilyUserData,
  UpdateFamilyUserData,
  UpdateFamilyData,
  Family,
} from "./types";



/*  ######################## */
/** ## FAMILY API REQUESTS # */
/*  ######################## */
const useFamilies = () => {
  const { setAppLoading } = useAppActions();

  /******************/
  /** Get My Family */
  const getMyFamily = () => {
    return new Promise<Family>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/families/my-family`,
      })
        .then((res) => {
          resolve(res.data as Family);
        })
        .catch((error) => {
          reject(error as ErrorResponse);
        });
    });
  };

  /******************/
  /** Update Family Details */
  const updateMyFamily = (data: UpdateFamilyData) => {
    return new Promise<Family>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/families/`,
        method: "patch",
        data,
      })
        .then((res) => {
          resolve(res.data as Family);
        })
        .catch((error) => {
          reject(error as ErrorResponse);
        });
    });
  };

  /***************************/
  /** Create new Family User */
  const createUserInFamily = (data: CreateFamilyUserData) => {
    setAppLoading(true);
    return new Promise<User>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/families/users/create`,
        method: "post",
        data,
      })
        .then((res) => {
          resolve(res.data as User);
        })
        .catch((error) => {
          reject(error as ErrorResponse);
        })
        .finally(() => {
          setAppLoading(false);
        });
    });
  };

  /****************************/
  /** List Users in My Family */
  const listFamilyUsers = () => {
    return new Promise<PaginatedResponse<User>>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/families/users/`,
      })
        .then((res) => {
          resolve(res.data as PaginatedResponse<User>);
        })
        .catch((error) => {
          reject(error as ErrorResponse);
        });
    });
  };

  /***********************/
  /** Get User in Family */
  const getUserInFamily = (userId: string) => {
    return new Promise<User>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/families/users/${userId}`,
      })
        .then((res) => {
          resolve(res.data as User);
        })
        .catch((error) => {
          reject(error as ErrorResponse);
        });
    });
  };

  /********************************/
  /** Update existing Family User */
  const updateUserInFamily = (userId: string, data: UpdateFamilyUserData) => {
    return new Promise<User>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/families/users/${userId}`,
        method: "patch",
        data,
      })
        .then((res) => {
          resolve(res.data as User);
        })
        .catch((error) => {
          reject(error as ErrorResponse);
        });
    });
  };

  /***********************/
  /** Remove User in Family */
  const removeUserInFamily = (userId: string) => {
    return new Promise<User>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/families/users/${userId}`,
        method: "delete",
        data: { userId },
      })
        .then((res) => {
          resolve(res.data as User);
        })
        .catch((error) => {
          reject(error as ErrorResponse);
        });
    });
  };

  /********************************/
  /** Reset Family Users Password */
  const resetUsersPassword = (userId: string, password: string) => {
    return new Promise<null>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/families/users/${userId}/reset-password`,
        method: "post",
        data: {
          userId,
          newPassword: password,
        },
      })
        .then(() => {
          resolve(null);
        })
        .catch((error) => {
          reject(error as ErrorResponse);
        });
    });
  };

  /********************************/
  /** Reset Family Users Password */
  const sendAdminInvite = (userId: string) => {
    return new Promise<null>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/families/users/${userId}/send-admin-invite`,
      })
        .then(() => {
          resolve(null);
        })
        .catch((error) => {
          reject(error as ErrorResponse);
        });
    });
  };

  /*********************************/
  /** Get the Primary Family Admin */
  const getPrimaryAdmin = () => {
    return new Promise<User | null>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/families/users/primary-admin`,
      })
        .then((res) => {
          resolve(res.data as User | null);
        })
        .catch((error) => {
          reject(error as ErrorResponse);
        });
    });
  };

  return {
    // Family
    getMyFamily,
    updateMyFamily,

    // Family Users
    createUserInFamily,
    listFamilyUsers,
    getUserInFamily,
    removeUserInFamily,
    updateUserInFamily,
    resetUsersPassword,
    sendAdminInvite,
    getPrimaryAdmin,
  };
};

export default useFamilies;
