import styled from "styled-components";

import { colors, functions, properties, snippets } from "../../../styles";

const Styles = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  ${snippets.flexCenter}
  z-index: ${properties.highestZIndex};
  margin: auto;
  .load-message {
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    color: ${colors.grey3};
  }
  &.app-loader {
    ${snippets.absoluteCenter};
    &::before {
      content: "";
      width: 100vw;
      height: 100vh;
      background-color: ${functions.rgba(colors.black, 0.8)};
      display: block;
      ${snippets.absoluteCenter};
    }
  }
`;

export default Styles;
