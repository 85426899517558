import { MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import anase_smile from
  "../../../assets/vector-images/ananse/bust_smile_staff_no glow.svg";
import { InfoPanel, Loader } from "../../../components/layout";
import { rootPaths } from "../../../routes/paths";




function SaveCard() {
  const navigate = useNavigate();
  const [addPayment, setAddPayment] = useState<boolean>(false);


  /*****************************************/
  /** Get Payment Page URL for saving card */
  const addPaymentMethod = (e: MouseEvent) => {
    e.preventDefault();
    setAddPayment(false);
  }


  useEffect(() => {
    navigate(rootPaths.SUBSCRIPTIONS);
  }, [])



  return (
    !addPayment ? (
      <Loader />
    ) : (
      <div className="page-wrapper">
        <InfoPanel
          icon={anase_smile}
          title="Add a Payment Method"
          description={(
            `To provide you with uninterrupted service, SONA needs${" "
            }to save your card details for automatic subscription renewals.`
          )}
        >
          <button
            className="button"
            onClick={addPaymentMethod}
          >
            Add Payment Method
          </button>
        </InfoPanel>
      </div>
    )
  );
}

export default SaveCard;