import countryCodesList from "country-codes-list";

import { SelectFieldOption } from "../components/forms/SelectField";



export type URLParams =
  | "userId"
  | "familyId"
  | "clanId"
  | "familyInviteId"
  | "clanInviteId"
  | "desertId"
  | "missionId"
  | "userMissionId"
  | "planPriceId"



export const getCountryCodes = (): SelectFieldOption[] => {
  const countries = countryCodesList.all();
  // Use a Set to track unique country calling codes
  const seenCodes = new Set();

  return countries
    .filter(country => {
      // Only keep the country if its calling code hasn't been seen before
      if (seenCodes.has(country.countryCallingCode)) {
        return false; // Skip duplicates
      }
      seenCodes.add(country.countryCallingCode); // Mark the code as seen
      return true;
    })
    .map(country => ({
      value: country.countryCallingCode,
      label: `+${country.countryCallingCode}`,
      description: (
        `${country.flag}${" "
        }(+${country.countryCallingCode})${" "
        }${country.countryNameLocal}${" "
        }${country.countryNameEn}`
      )
    }));
}