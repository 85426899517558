import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { FormField, FormValues } from "../../../components/forms/Form/types";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import { useQueryParams } from "../../../utils/components";
import { SignInData } from "../../../requests/auth/types";
import Form from "../../../components/forms/Form";
import useAuth from "../../../requests/auth";
import Styles from "./styles";

function SignIn() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { signIn } = useAuth();
  const { queryParams } = useQueryParams();
  const formRef = useRef<HTMLFormElement>(null);
  const [formValues, setFormValues] = useState<FormValues>({});
  const username = queryParams.get("username");

  /****************/
  /** Submit Data */
  const submitData = () => {
    const data = formValues as SignInData;
    signIn(data)
      .then(() => {
        navigate(nonAuthPaths.LOAD_TOKEN, { state });
      })
      .catch(() => null);
  };

  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "text",
      name: "username",
      label: "Email or Username",
      placeholder: "you@example.com or your-username",
      autoComplete: "email",
      groupClass: "field-group",
    },
    {
      type: "password",
      name: "password",
      label: "Password",
      placeholder: "Your secure password",
      autoComplete: "current-password",
      groupClass: "field-group",
    },
  ];

  useEffect(() => {
    if (!username) return;
    setFormValues((prevState) => ({
      ...prevState,
      username,
    }));
  }, [username]);

  return (
    <Styles className="root-content bg-action-pose">
      <div className="page-wrapper field-width centered">
        {/* Heading */}
        <header>
          <h3 className="title">Welcome to SONA!</h3>
          <div className="description">
            Access your SONA account using your email and password
          </div>
        </header>

        {/* Sign-In Form */}
        <section>
          <Form
            fields={formFields}
            values={formValues}
            setValues={setFormValues}
            onSubmit={submitData}
            ref={formRef}
          >
            <button className="button submit full-width" type="submit">
              Sign In
            </button>
          </Form>
          <div className="flex forgot-password mt-1">
            <div>Forgot Password?</div>
            <Link className="link" to={nonAuthPaths.FORGOT_PASSWORD}>
              Reset here
            </Link>
          </div>
        </section>

        {/* Alternate Authentication (Sign Up) */}
        <section className="alt-entry flex center">
          <div>Don't have an account?</div>
          <Link to={nonAuthPaths.SIGN_UP} className="link underline black">
            Sign Up
          </Link>
        </section>
      </div>
    </Styles>
  );
}

export default SignIn;
