import styled from "styled-components";

import { colors, functions, resolutions, snippets } from "../../../styles";

const Styles = styled.div`
  background-color: ${colors.white};
  header {
    padding: 3rem;
    ${snippets.flexCenter}
    height: fit-content;
    h1 {
      font-size: 3vw;
    }
    .subtitle {
      font-size: 1.2vw;
      margin: 1rem 0;
      color: ${colors.grey2};
    }
    .form {
      flex: unset;
      .field-column {
        label {
          opacity: 0;
        }
        .text-field {
          border-width: 3px;
          border-color: ${colors.primary};
          input {
            padding: 1rem;
            font-size: 2rem;
            text-align: center;
          }
        }
      }
    }
  }
  .page-wrapper {
    flex-direction: row !important;
    gap: 2rem;
    @media (max-width: ${resolutions.tablet}) {
      flex-wrap: wrap;
    }
    .ananse {
      max-width: 40%;
    }
    .ananse-message {
      font-size: 1.5rem;
      max-width: 400px;
      margin: 0 auto;
      margin-top: 1rem;
      text-align: center;
      white-space: pre-line;
    }
    .plan {
      background-color: ${colors.grey4};
      padding: 2rem 1rem;
      border: 5px solid ${functions.rgba(colors.primary, 0.3)};
      & > * {
        text-align: center;
        justify-content: center;
        align-items: center;
      }
      .price {
        justify-content: center;
      }
      li.feature {
        width: fit-content;
        margin: 0 auto;
      }
      .action.button {
        margin: 0 auto;
      }
    }
  }
`;

export default Styles;
