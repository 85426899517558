import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { FormField, FormValues } from "../../../components/forms/Form/types";
import { useAppModal } from "../../../components/layout/AppModal/utils";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import { useQueryParams } from "../../../utils/components";
import { isEmail } from "../../../utils/strings";
import { Form } from "../../../components/forms";
import useUsers from "../../../requests/users";
import Styles from "./styles";



function SetNewPassword() {
  const navigate = useNavigate();
  const { setAppModal } = useAppModal();
  const { queryParams } = useQueryParams();
  const { setNewPassword } = useUsers();
  const fullName = queryParams.get("fullName") || "";
  const username = queryParams.get("username") || "";
  const formRef = useRef<HTMLFormElement>(null);
  const [formValues, setFormValues] = useState<FormValues>({});


  /*********************/
  /** Set New Password */
  const submitData = () => {
    if (!username) {
      navigate(nonAuthPaths.FORGOT_PASSWORD);
      return;
    }
    setNewPassword({
      username,
      password: formValues.password
    }).then(() => {
      const message = (
        `Your password has been successfully set!${" "
        }You can now login with it.`
      );
      setAppModal({
        title: "Password Set",
        children: message,
        cancelCallback: () => {
          const encodedUsername = encodeURIComponent(username);
          navigate(`${nonAuthPaths.SIGN_IN
            }?username=${encodedUsername}`);
        }
      });
    }).catch(() => null);
  }

  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "text",
      name: "username",
      label: isEmail(username) ? "Email" : "Username",
      autoComplete: "username",
      disabled: true,
      groupClass: "field-group"
    },
    {
      type: "password",
      name: "password",
      label: "Password",
      placeholder: "Your new secure password",
      autoComplete: "new-password",
      username: username,
      groupClass: "field-group"
    },
    {
      type: "password",
      name: "confirm-password",
      label: "Confirm Password",
      placeholder: "Re-enter your password",
      autoComplete: "new-password",
      groupClass: "field-group"
    }
  ]

  useEffect(() => {
    if (!username) return;
    setFormValues((prevState) => ({
      ...prevState,
      username
    }))
  }, [username])



  return (
    <Styles className="root-content bg-action-pose">
      <div className="page-wrapper field-width centered">
        {/* Heading */}
        <header>
          <h3 className="title">
            Set your Password
          </h3>
          <div className="description">
            {`Hello 👋🏾 ${fullName}`.trim()}<br />
            We're excited for you to see the SONA app.
            <br />
            To get started, set up a strong password
            <br />
            to protect your account.
          </div>
        </header>
        {/* Set Password Form */}
        <section>
          <Form
            fields={formFields}
            values={formValues}
            setValues={setFormValues}
            onSubmit={submitData}
            ref={formRef}
          >
            <button
              className="button submit full-width"
              type="submit"
            >
              Set Password
            </button>
          </Form>
          <div className="flex forgot-password mt-1">
            <div>Having issues?</div>
            <Link
              className="link"
              to={nonAuthPaths.FORGOT_PASSWORD}
            >
              Click here to start over
            </Link>
          </div>
        </section>
      </div>
    </Styles>
  );
}

export default SetNewPassword;