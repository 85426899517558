import {
  SetStateAction, useState, Dispatch, useEffect, useCallback
} from "react";
import {
  Outlet, useOutletContext, useLocation, useNavigate, Link
} from "react-router-dom";

import play_store from "../../assets/vector-images/brands/play-store.svg";
import app_store from "../../assets/vector-images/brands/app-store.svg";
import { profileSetupPaths } from "../../routes/profileSetup/paths";
import { AuthRouteContext } from "../../components/auth/AuthRoute";
import { getLastUrlSegment, isEmail } from "../../utils/strings";
import useOnboardingUtils from "../../requests/onboarding/utils";
import { securityPaths } from "../../routes/security/paths";
import useOnboarding from "../../requests/onboarding";
import useAppActions from "../../redux/actions/app";
import { SideBar } from "../../components/layout";
import { useAppState } from "../../redux/state";
import { rootPaths } from "../../routes/paths";
import Styles from "./styles";



// Context (Props) passed down to Child Pages (<Outlet/>)
export type ConsoleContext = AuthRouteContext & {
  hideFooter: boolean;
  setHideFooter: Dispatch<SetStateAction<boolean>>;
}

function Console() {
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { sidebarOpen } = useAppState();
  const { setSidebarOpen } = useAppActions();
  const { getMyOnboarding } = useOnboarding();
  const { getRequiredOnboarding } = useOnboardingUtils();
  const { user } = useOutletContext<AuthRouteContext>();
  const [hideFooter, setHideFooter] = useState<boolean>(false);
  const [secondaryOnboarding, setSecondaryOnboarding] = useState<boolean>();


  /**********************************/
  /** Get User's Onboarding Profile */
  const getOnboarding = useCallback(() => {
    getMyOnboarding()
      .then((onboarding) => {
        getRequiredOnboarding(onboarding, user)
          .then((partialOnboarding) => {
            const onboardingRequired = Object.values(
              partialOnboarding
            ).some(value => !value);
            setSecondaryOnboarding(onboardingRequired);
          }).catch(() => null);
      }).catch(() => null);

  }, [])

  const updatePageConfig = useCallback(() => {
    const path = getLastUrlSegment(pathname);
    if (path.toLowerCase() === "download") {
      setHideFooter(true)
    } else {
      setHideFooter(false);
    }
  }, [pathname])


  useEffect(() => {
    updatePageConfig();
  }, [updatePageConfig])

  useEffect(() => {
    const handleResize = () => {
      setSidebarOpen(
        (window.innerWidth >= 992) &&
        (pathname.startsWith("/manage"))
      );
    };
    window.addEventListener("resize", handleResize);
    // Run once to set initial state based on current window size
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [pathname]);

  useEffect(() => {
    getOnboarding();
    // Ensure email is verified
    if ((isEmail(user.username)) && !user.emailVerified) {
      navigate(securityPaths.VERIFY_EMAIL);
      return;
    }
    // Ensure onboarding is completed
    // Onboarding Completed = User Profile + Optional KYC
    if (!user.completedOnboarding) {
      navigate(rootPaths.PROFILE_SETUP);
      return;
    }
  }, [user, getOnboarding])



  return (
    <Styles className="Console root-content">
      {/* SideBar */}
      <SideBar
        user={user}
        open={sidebarOpen}
        setOpen={setSidebarOpen}
      />

      <div id="Console">
        {/* Onboarding Prompt */}
        {secondaryOnboarding ? (
          <div
            className="onboarding-prompt flex wrap align-center justify-between"
          >
            <p className="message">
              {user.firstName
              }, there are a few things left to finish setting up your account!
            </p>
            <Link
              to={profileSetupPaths.SECONDARY_CHECKLIST}
              className="button sm"
            >
              Complete Setup
            </Link>
          </div>
        ) : null}
        {/* Page Content */}
        <Outlet
          context={{
            user,
            hideFooter,
            setHideFooter
          }}
        />

        {/* App Download links in footer */}
        {!hideFooter ? (
          <footer className="console-footer flex justify-between align-center">
            <div className="grey-text">
              Download SONA from the App Store or Google Play Store
            </div>
            <div className="flex align-center app-links">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/app/sona-school-of-new-africa/id6465394291"
                className="store-link"
              >
                <img src={app_store} />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.schoolofnewafrica.app"
                className="store-link"
              >
                <img src={play_store} />
              </a>
            </div>
          </footer>
        ) : null}
      </div>
    </Styles>
  );
}

export default Console;