import { useEffect, useRef } from "react";

import { UpdateFamilyData } from "../../../requests/families/types";
import { usePageControls } from "../../../utils/components";
import { FormField } from "../../../components/forms/Form/types";
import useFamilies from "../../../requests/families";
import { FamilySetupFormProps } from "./types";
import { Form } from "../../../components/forms";



function NameForm({
  FormNavigation,
  values: formValues,
  setValues: setFormValues,
  nextStep,
  family
}: FamilySetupFormProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const { previousPage } = usePageControls();
  const { updateMyFamily } = useFamilies();


  /*********************/
  /** Save Family Data */
  const saveData = () => {
    const data: UpdateFamilyData = {
      name: formValues.name
    }
    updateMyFamily(data)
      .then(() => {
        nextStep();
      }).catch(() => null);
  }

  /****************/
  /** Form Fields */
  const fields: FormField[] = [
    {
      type: "react-node",
      name: "",
      label: "",
      groupClass: "field-group mb-1",
      reactNode: (
        <div className="field-info">
          You can set a unique family name made up{" "
          }of one to five words and should be descriptive{" "
          }of your family.
        </div>
      )
    },
    {
      type: "text",
      name: "name",
      label: "Family Name",
      groupClass: "field-group"
    }
  ]

  useEffect(() => {
    if (!family) return;
    setFormValues(prevState => ({
      ...prevState,
      name: family.name
    }));
  }, [family])



  return (
    <Form
      values={formValues}
      setValues={setFormValues}
      onSubmit={saveData}
      ref={formRef}
      fields={fields}
    >
      <FormNavigation
        previous={(
          <button
            className="button white-black previous"
            type="button"
            onClick={() => previousPage()}
          >
            Cancel
          </button>
        )}
        next={(
          <button
            className="button next"
            type="submit"
          >
            Save & Continue
          </button>
        )}
      />
    </Form>
  );
}

export default NameForm;