import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";

import { AuthRouteContext } from "../../components/auth/AuthRoute";
import { Address } from "../../requests/addresses/types";
import { getLastUrlSegment } from "../../utils/strings";
import useAddresses from "../../requests/addresses";
import { User } from "../../requests/users/types";
import useUsers from "../../requests/users";
import Styles from "./styles";



// Context (Props) passed down to Child Pages (<Outlet/>)
export interface ProfileSetupContext extends AuthRouteContext {
  address: Address;
}

function ProfileSetup() {
  const { user: loggedInUser } = useOutletContext<AuthRouteContext>();
  const { pathname } = useLocation();
  const { getUserProfile } = useUsers();
  const { getMyAddress } = useAddresses();
  const [user, setUser] = useState<User>(loggedInUser);
  const [address, setAddress] = useState<Address>();
  const [rootClassName, setRootContentClassName] = useState<string>(
    "root-content bg-action-pose faded"
  );


  /*****************************/
  /** Get the latest user data */
  const getUpdatedUser = () => {
    getUserProfile()
      .then((resData) => {
        setUser(resData)
      }).catch(() => null)
  }

  /********************************/
  /** Get the latest address data */
  const getUpdatedAddress = () => {
    getMyAddress()
      .then((resData) => {
        setAddress(resData);
      }).catch(() => null);
  }

  const updatePageConfig = useCallback(() => {
    // Update Progress Bar
    const path = getLastUrlSegment(pathname);
    switch (path) {
      case "name":
      case "nickname":
      case "dob":
      case "kyc":
        setRootContentClassName("root-content bg-action-pose faded")
        getUpdatedUser();
        break;
      case "country":
      case "address":
        setRootContentClassName("root-content bg-action-pose faded")
        getUpdatedAddress();
        break;
      case "avatar":
        setRootContentClassName("root-content");
        getUpdatedUser();
        break;
      case "summary":
        setRootContentClassName("root-content bg-action-pose faded");
        getUpdatedUser();
        getUpdatedAddress();
        break;
      case "verification-success":
        setRootContentClassName("root-content bg-pattern-01");
        break;
      default:
        setRootContentClassName("root-content bg-action-pose faded")
    }
  }, [pathname]);


  useEffect(() => {
    updatePageConfig();
  }, [updatePageConfig])



  return (
    <Styles className={rootClassName}>
      <div className="profile-setup-wrapper">
        <Outlet
          context={{
            user,
            address
          }}
        />
      </div>
    </Styles>
  );
}

export default ProfileSetup;