import {
  MouseEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { ReactComponent as ArrowIcon } from "../../../assets/vector-images/generic/chevron-bottom.svg";
import { useHiddenOutside } from "../../../utils/components";
import { useInput } from "../../../utils/forms";
import TextField from "../TextField";
import Styles from "./styles";

export type SelectFieldOption = {
  value: string;
  label: ReactNode;
  description?: ReactNode;
};

export interface Props
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  name?: string;
  value: string;
  onChange: any;
  options?: any[];
  icon?: string;
  valueKey?: string;
  labelKey?: string;
  descriptionKey?: string;
  placeholder?: string;
  showSearch?: boolean;
}

function SelectField({
  name,
  value,
  options = [],
  placeholder,
  showSearch = true,
  valueKey = "value",
  labelKey = "label",
  descriptionKey = "description",
  onChange,
}: Props) {
  const [displayValue, setDisplayValue] = useState<string>("");
  const dropDownParent = useRef<HTMLDivElement>(null);
  const {
    value: search,
    stringChange: searchCh,
    setValue: setSearch,
  } = useInput({
    initialValue: "",
  });
  const { visible: dropdown, setVisible: setDropdown } = useHiddenOutside(
    dropDownParent,
    () => setSearch("")
  );

  const filteredOptions = useMemo(() => {
    let newOptions = options;
    if (search) {
      newOptions = options.filter(
        (option) =>
          option[labelKey].toLowerCase().includes(search.toLowerCase()) ||
          (descriptionKey &&
            option[descriptionKey].toLowerCase().includes(search.toLowerCase()))
      );
    }

    return newOptions;
  }, [search, options]);

  const chooseOption = (e: MouseEvent, option: any) => {
    e.preventDefault();
    setSearch("");
    onChange(option[valueKey]);
    setDropdown(false);
  };

  useEffect(() => {
    if (!value) return;
    const newValue = options.find((o) => o[valueKey] === value);
    if (newValue) setDisplayValue(newValue[labelKey]);
  }, [value]);

  return (
    <Styles className="field select-field" ref={dropDownParent}>
      <button
        className="selected-value flex align-center justify-between"
        id={name}
        type="button"
        onClick={() => setDropdown(!dropdown)}
      >
        <div className="value">
          {value && displayValue
            ? displayValue
            : placeholder
            ? placeholder
            : "Select an option"}
        </div>
        <ArrowIcon />
      </button>

      {/* Dropdown options */}
      {dropdown ? (
        <div className="option-dropdown">
          {showSearch ? (
            <TextField
              name="select-search"
              className="select-search"
              value={search}
              onChange={searchCh}
              required={false}
              placeholder="Search ..."
            />
          ) : null}
          <ul className="options">
            {filteredOptions.map((option, index) => (
              <li
                key={`${option}-${index}`}
                value={option[valueKey]}
                className="option"
              >
                <button
                  onClick={(e) => chooseOption(e, option)}
                  className={`option-button ${
                    value === option[valueKey] ? "selected" : ""
                  }`}
                >
                  {option[labelKey]}
                </button>
              </li>
            ))}
            {filteredOptions.length === 0 ? (
              <li className="option empty">No results found for "{search}"</li>
            ) : null}
          </ul>
        </div>
      ) : null}
    </Styles>
  );
}

export default SelectField;
