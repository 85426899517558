import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { PlanPriceDiscount } from "../../../requests/planPriceDiscounts/types";
import { FormField, FormValues } from "../../../components/forms/Form/types";
import usePlanPriceDiscounts from "../../../requests/planPriceDiscounts";
import ananse_smile from
  "../../../assets/vector-images/ananse/bust_smile_staff_no glow.svg";
import ananse_sad from
  "../../../assets/vector-images/ananse/bust_sad_stafff_no glow.svg";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import { PlanPrice } from "../../../requests/plans/types";
import { PlanDetail } from "../../../components/layout";
import useAppActions from "../../../redux/actions/app";
import { useCheckoutUtils } from "../Checkout/utils";
import {
  getDiscountedPrice
} from "../../../requests/planPriceDiscounts/utils";
import { useAppState } from "../../../redux/state";
import { Form } from "../../../components/forms";
import Styles from "./styles";



type AnanseState = {
  message: string;
  image: string;
}

function VoucherEntry() {
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();
  const { token } = useAppState();
  const { pathname } = useLocation();
  const [formValues, setFormValues] = useState<FormValues>({});
  const [discountCode, setDiscountCode] = useState<
    PlanPriceDiscount & { planPrice: PlanPrice } | null
  >(null);
  const { getPlanPriceDiscountByCode } = usePlanPriceDiscounts();
  const { setAppLoading } = useAppActions();
  const [ananse, setAnanse] = useState<AnanseState | null>(null);
  const { checkoutPlan } = useCheckoutUtils();


  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "text",
      name: "code",
      label: "Voucher Code",
      placeholder: "Enter your voucher code",
      autoComplete: "off",
      groupClass: "field-group"
    }
  ]

  /****************/
  /** Submit Data */
  const submitData = async () => {
    setAppLoading(true);
    const code = formValues.code;
    try {
      const discount = await getPlanPriceDiscountByCode(code);
      setDiscountCode(discount);
      setAnanse({
        image: ananse_smile,
        message: (
          `Awesome!\nYour voucher gives you ${discount.percentage
          }% off the ${discount.planPrice.plan.name
          } plan for a ${discount.planPrice.interval}.`
        )
      });
    } catch {
      setAnanse({
        image: ananse_sad,
        message: (
          `We couldn't find valid a discount with that code.${" "
          }Please double-check and try again.`
        )
      });
      setDiscountCode(null);
    } finally {
      setAppLoading(false)
    }
  }


  useEffect(() => {
    if (!token) {
      navigate(nonAuthPaths.SIGN_UP, {
        state: { previousPath: pathname },
      });
    }
  }, [token])



  return (
    <Styles className="root-content">
      <header>
        <h1>SONA VOUCHER</h1>
        <div className="subtitle">
          Got a voucher code? Enter it below to unlock your savings!
        </div>
        <Form
          fields={formFields}
          values={formValues}
          setValues={setFormValues}
          onSubmit={submitData}
          ref={formRef}
        >
          <button className="button full-width" type="submit">
            Find Voucher
          </button>
        </Form>
      </header>
      <div className="page-wrapper flex center">
        {ananse ? (
          <figure>
            <img src={ananse.image} alt="" className="ananse" />
            <figcaption className="ananse-message">
              {ananse.message}
            </figcaption>
          </figure>
        ) : null}
        {discountCode ? (
          <PlanDetail
            plan={discountCode.planPrice.plan}
            planPrice={discountCode.planPrice}
            activeSubscription={null}
            discountedPrice={getDiscountedPrice(
              discountCode.planPrice.amount,
              discountCode.percentage
            )}
            action={(
              <button
                className="button choose-plan action"
                onClick={(e) => checkoutPlan(
                  e, discountCode.planPrice, discountCode.planPrice.plan,
                  discountCode.code
                )}
              >
                Subscribe to Plan
              </button>
            )}
          />
        ) : null}
      </div>
    </Styles>
  );
}

export default VoucherEntry;