import { useCallback, useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { Loader, VideoPlayer } from "../../../components/layout";
import { UserMission } from "../../../requests/missions/types";
import useMissions from "../../../requests/missions";
import { URLParams } from "../../../constants";
import useLessonUtils from "./utils";
import { MissionsContext } from "..";
import Styles from "./styles";



function MissionDetail() {
  const { desertId, userMissionId } = useParams<URLParams>();
  const { user } = useOutletContext<MissionsContext>();
  const [userMission, setUserMission] = useState<UserMission>();
  const { getMission } = useMissions();
  const { playing, onStart, onEnded } = useLessonUtils(
    user, desertId, userMission
  );


  /********************/
  /** Get User Mission */
  const retrieveUserMission = useCallback(async (id: number | string) => {
    const params = { depth: 1 }
    try {
      const resData = await getMission(id, params)
      setUserMission(resData);
    } catch { }
  }, [])

  useEffect(() => {
    if (!userMissionId) return;
    retrieveUserMission(userMissionId)
  }, [retrieveUserMission])



  return (
    <Styles className="page-wrapper">
      {userMission ? (
        <>
          <VideoPlayer
            playing={playing}
            onReady={onStart}
            onEnded={onEnded}
            url={
              userMission.mission.fallbackVideoUrl
                ? userMission.mission.fallbackVideoUrl
                : userMission.mission.videoUrl
            }
          />
        </>
      ) : (
        <Loader />
      )}
    </Styles>
  );
}

export default MissionDetail;