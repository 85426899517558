import { useEffect, useRef } from "react";

import { familyRelationships, familyRoles } from "../../../constants/auth";
import { usePageControls } from "../../../utils/components";
import { FormField } from "../../../components/forms/Form/types";
import { Form } from "../../../components/forms";
import { FormPageProps } from "./types";



function Permissions({
  FormNavigation,
  values: formValues,
  setValues: setFormValues,
  setEmailUsername,
  nextStep,
  user
}: FormPageProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const { previousPage } = usePageControls();


  /****************/
  /** Form Fields */
  const fields: FormField[] = [
    {
      type: "select",
      name: "familyRole",
      label: "Account Type",
      showSearch: false,
      options: familyRoles,
      groupClass: "field-group",
      disabled: user !== undefined,
    },
    {
      type: "select",
      name: "familyRelationship",
      showSearch: false,
      label: "Family Relationship",
      options: familyRelationships(formValues.familyRole),
      groupClass: "field-group"
    }
  ]


  useEffect(() => {
    if (!formValues) return;
    if (
      formValues["familyRole"] &&
      (formValues["familyRole"] === "admin")
    ) {
      setEmailUsername(true);
    } else {
      setEmailUsername(false);
    }
  }, [formValues])

  useEffect(() => {
    if (!user) return;
    const {
      familyRole, familyRelationship,
    } = user;
    setFormValues((prevState) => ({
      ...prevState,
      familyRole,
      familyRelationship
    }));
  }, [user])



  return (
    <Form
      values={formValues}
      setValues={setFormValues}
      onSubmit={nextStep}
      ref={formRef}
      fields={fields}
    >
      <FormNavigation
        previous={(
          <button
            className="button white-black previous"
            type="button"
            onClick={() => { previousPage() }}
          >
            Cancel
          </button>
        )}
      />
    </Form>
  );
}

export default Permissions;