import { Link, useOutletContext } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import check_icon from "../../../assets/vector-images/generic/check-fill.svg";
import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import useOnboardingUtils from "../../../requests/onboarding/utils";
import { Onboarding } from "../../../requests/onboarding/types";
import { consolePaths } from "../../../routes/_console/paths";
import { familyPaths } from "../../../routes/family/paths";
import useOnboarding from "../../../requests/onboarding";
import { Loader } from "../../../components/layout";
import { ProfileSetupContext } from "..";
import Styles from "./styles";



type Checklist = {
  name: string;
  title: string;
  completed: boolean;
  path: string;
}

function SecondaryChecklist() {
  const { user } = useOutletContext<ProfileSetupContext>();
  const { getMyOnboarding } = useOnboarding();
  const { getRequiredOnboarding } = useOnboardingUtils();
  const [checklist, setChecklist] = useState<Checklist[]>([]);
  const [allCompleted, setAllCompleted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);


  /****************************************/
  /** Build Checklist of onboarding tasks */
  const populateChecklist = (onboarding: Partial<Onboarding>) => {
    const checklist: Checklist[] = Object.entries(onboarding).map((
      [key, value]
    ) => {
      switch (key) {
        case "avatar":
          return {
            name: key,
            title: "Choose your Avatar",
            completed: value as boolean,
            path: profileSetupPaths.AVATAR_SELECT
          };
        case "heritages":
          return {
            name: key,
            title: "Identify your Heritages",
            completed: value as boolean,
            path: profileSetupPaths.HERITAGES
          };
        case "family":
          return {
            name: key,
            title: "Create a Family",
            completed: value as boolean,
            path: familyPaths.setup("")
          };
        case "completedKyc":
          if (user.familyRole === "member")
            return null;
          else
            return {
              name: key,
              title: "Verify your Identity",
              completed: value as boolean,
              path: profileSetupPaths.KYC_UPLOAD_DOCUMENT
            };
        default:
          return null;
      }
    }).filter(item => item !== null) as Checklist[];
    setChecklist(checklist);
  }

  /**********************************/
  /** Get User's Onboarding Profile */
  const getOnboarding = useCallback(() => {
    getMyOnboarding()
      .then((onboarding) => {
        getRequiredOnboarding(onboarding, user)
          .then((partialOnboarding) => {
            populateChecklist(partialOnboarding);
          }).catch(() => null);
      }).catch(() => null);
  }, [])


  useEffect(() => {
    getOnboarding();
  }, [getOnboarding])

  useEffect(() => {
    if (!checklist.length) return;
    setAllCompleted(checklist.every(
      item => item.completed
    ));
    setLoading(false)
  }, [checklist])



  return (
    <Styles className="page-wrapper">
      {/* Heading */}
      <header>
        <h3 className="title">
          Continue Account Setup
        </h3>
        <div className="subtitle">
          Here are the next steps to complete your account setup.
          <br />
          You can always skip and do this later
        </div>
      </header>
      {loading ? (
        <Loader />
      ) : (
        <section>
          <ul className="checklist-items flex column">
            {checklist.map((item, index) =>
              <Link
                to={item.path}
                key={`${item.name}-${index}`}
                className={`checklist-item flex align-center ${item.completed
                  ? "completed"
                  : ""}`
                }
              >
                {item.completed ? (
                  <img src={check_icon} alt="" className="image fill" />
                ) : (
                  <div className="image placeholder"></div>
                )}
                <div className="title">
                  {item.title}
                </div>
              </Link>
            )}
          </ul>
          {allCompleted ? (
            <div className="go-home">
              <p className="message al-center mb-1">
                Looks like you're all done!
              </p>
              <Link
                to={consolePaths.DASHBOARD}
                className="button full-width"
              >
                Go to Dashboard
              </Link>
            </div>
          ) : (
            <Link
              to={consolePaths.DASHBOARD}
              className="go-home link black underline">
              Skip and do this later
            </Link>
          )}
        </section>
      )}
    </Styles>
  );
}

export default SecondaryChecklist;