import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  ReactComponent as BackIcon
} from "../../../assets/vector-images/generic/chevron-left.svg";
import { getVerboseRegionName } from "../../../constants/core";
import { missionPaths } from "../../../routes/missions/paths";
import { useQueryParams } from "../../../utils/components";
import { Region } from "../../../requests/trees/types";
import { Loader } from "../../../components/layout";
import useTrees from "../../../requests/trees";
import { URLParams } from "../../../constants";
import MissionList from "../MissionList";
import Styles from "./styles";



type TreeRegion = {
  id: number;
  region: Region;
  treeId: number;
}
function DesertMissions() {
  const navigate = useNavigate();
  const { desertId } = useParams<URLParams>();
  const { pathname } = useLocation();
  const { queryParams } = useQueryParams();
  const trId = queryParams.get("treeId") || "";
  const { getUserTrees } = useTrees();
  const [loading, setLoading] = useState<boolean>(true);
  const [treeRegions, setTreeRegions] = useState<TreeRegion[]>([]);
  const [treeId, setTreeId] = useState<number>();
  const [desertName, setDesertName] = useState<string>("");


  /**************************************/
  /** List User Missions in this Desert */
  const getDesertTrees = (desertId: string | number) => {
    setLoading(true);
    const params = { desertId }
    getUserTrees(params)
      .then((userTrees) => {
        setDesertName(userTrees[0].tree.desert.name)
        setTreeRegions(userTrees.map((userTree) => ({
          id: userTree.id,
          region: userTree.tree.region,
          treeId: userTree.tree.id
        })));
        if (!trId) {
          setTreeId(userTrees[0].tree.id)
        }
      })
      .catch(() => {
        navigate(missionPaths.map(pathname))
      })
      .finally(() => {
        setLoading(false)
      });
  }

  useEffect(() => {
    if (desertId) getDesertTrees(desertId);
  }, [desertId])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    if (trId)
      setTreeId(parseInt(trId));
  }, [trId])





  return (
    (!desertId || loading) ? (
      <Loader />
    ) : (
      <Styles className="page-wrapper">
        <header>
          <div className="flex center top-row">
            <Link
              to={missionPaths.map(pathname)}
              className="flex align-center back-icon"
            >
              <BackIcon />
              <div className="text">
                Map of Space & Time
              </div>
            </Link>
            <h1 className="main-title">
              {desertName}
            </h1>
          </div>
          <div className="regions flex center">
            {treeRegions.map((treeRegion) =>
              <Link
                key={treeRegion.id}
                className={
                  `region-link ${(treeRegion.treeId === treeId) ? "active" : ""}`
                }
                to={`${missionPaths.desertMissions(desertId ?? "", pathname)
                  }/?treeId=${treeRegion.treeId}`}
              >
                {getVerboseRegionName(treeRegion.region)}
              </Link>
            )}
          </div>
        </header>
        {treeId ? (
          <MissionList
            desertId={desertId}
            treeId={treeId}
          />
        ) : null}
      </Styles>
    )
  );
}

export default DesertMissions;