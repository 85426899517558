import { Outlet, useOutletContext } from "react-router-dom";

import { AuthRouteContext } from "../../components/auth/AuthRoute";
import Styles from "./styles";



export type WalletContext = AuthRouteContext;
function Wallet() {
  const walletContext = useOutletContext<WalletContext>();

  return (
    <Styles className="root-content">
      <Outlet context={walletContext} />
    </Styles>
  );
}

export default Wallet;