import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { FormValues } from "../../../components/forms/Form/types";
import { MissionQuizzes } from "../../../components/missions";
import { missionPaths } from "../../../routes/missions/paths";
import {
  MissionResult,
  MissionResultCreateData, ShallowQuizResult, UserMission
} from "../../../requests/missions/types";
import useMissions from "../../../requests/missions";
import { Loader } from "../../../components/layout";
import { URLParams } from "../../../constants";
import Styles from "./styles";
import QuizResults from "./Result";



function MissionDetail() {
  const { userMissionId } = useParams<URLParams>();
  const { getMission, submitMissionResults } = useMissions();
  const [userMission, setUserMission] = useState<UserMission>();
  const [formValues, setFormValues] = useState<FormValues>({});
  const [result, setMissionResult] = useState<MissionResult>();
  const [loading, setLoading] = useState<boolean>(true);



  /********************/
  /** Get User Mission */
  const retrieveUserMission = useCallback(async (id: number | string) => {
    const params = { depth: 2 }
    try {
      const resData = await getMission(id, params)
      setUserMission(resData);
    } catch { }
    finally {
      setLoading(false);
    }
  }, [])

  /*******************/
  /** Submit Mission */
  const submitMission = async (userMission: UserMission) => {
    setLoading(true);
    const quizResults: ShallowQuizResult[] = Object.entries(
      formValues
    ).map(([key, value]) => ({
      quizId: parseInt(key.toString()),
      optionId: parseInt(value.toString())
    }));
    const userMissionResult: MissionResultCreateData = {
      userMissionId: userMission.id,
      quizResults
    }
    try {
      const resData = await submitMissionResults(userMissionResult);
      setMissionResult(resData);
    } catch { }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!userMissionId) return;
    retrieveUserMission(userMissionId)
  }, [retrieveUserMission])



  return (
    <Styles className="page-wrapper">
      {loading ? (
        <Loader />
      ) : result ? (
        <QuizResults missionResult={result} />
      ) : userMission ? (
        <MissionQuizzes
          quizzes={userMission.mission.quizzes}
          lessonLink={missionPaths.missionLesson(
            userMission.mission.tree.desertId,
            userMission.id
          )}
          formValues={formValues}
          setFormValues={setFormValues}
          onSubmit={() => submitMission(userMission)}
        />
      ) : (
        <Loader />
      )}
    </Styles>
  );
}

export default MissionDetail;