import styled from "styled-components";

import { colors, functions } from "../../../styles";



const Styles = styled.div``;

export const LessonModal = styled.figure`
  .img-wrapper {
    width: 90px;
    height: 90px;
    background-color: ${functions.rgba(colors.accent10, 0.3)};
    border-radius: 50%;
    overflow: hidden;
    padding-top: 1rem;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  figcaption {
    .description {
      white-space: pre;
    }
  }
`;

export default Styles;