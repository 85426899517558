import { getRootPath } from "../utils";



export const familyRoot = "/family";

/*****************/
/** Family Paths */
export const familyPaths = {
  setup: (pathname: string = "") =>
    `${getRootPath(pathname, familyRoot)}${familyRoot}/setup`,
  detail: (pathname: string = "") =>
    `${getRootPath(pathname, familyRoot)}${familyRoot}/`,
  addUser: (pathname: string = "") =>
    `${getRootPath(pathname, familyRoot)}${familyRoot}/users/add/`,
  EDIT_USER: `${familyRoot}/users/:userId/edit/`,
  editUser: (userId: string, pathname: string = "") =>
    `${getRootPath(pathname, familyRoot)}${familyRoot}/users/${userId}/edit/`,
  createInvite: (pathname: string = "") =>
    `${getRootPath(pathname, familyRoot)}${familyRoot}/invites/create/`,
  viewInvites: (pathname: string = "") =>
    `${getRootPath(pathname, familyRoot)}${familyRoot}/invites/`,
}