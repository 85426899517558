import axios from "axios";

import { useAppModal } from "../../components/layout/AppModal/utils";
import useAppActions from "../../redux/actions/app";
import { ErrorResponse } from "../../types";
import {
  RequestPasswordResetData,
  ResetPasswordData,
  SendVerificationData,
  SignInData,
  SignInResponse,
  SignUpData,
  SignUpResponse,
} from "./types";

/*  ###################### */
/** ## AUTH API REQUESTS # */
/*  ###################### */
const useAuth = () => {
  const { setAppLoading, saveUser, saveToken, clearToken } = useAppActions();
  const { setAppModal, closeModal } = useAppModal();

  /*******************************************/
  /** Sign in with Email/Username & Password */
  const signIn = (data: SignInData) => {
    return new Promise<SignInResponse | ErrorResponse>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL}/v1/auth/sign-in`,
        method: "post",
        data,
      })
        .then((res) => {
          const response = res.data as SignInResponse;
          const { authToken, ...userData } = response;
          saveUser(userData);
          saveToken(authToken.token);
          resolve(response);
        })
        .catch((error) => {
          reject(error as ErrorResponse);
        })
        .finally(() => {
          setAppLoading(false);
        });
    });
  };

  /**********************************/
  /** Sign up with Email & Password */
  const signUp = (data: SignUpData) => {
    return new Promise<SignInResponse | ErrorResponse>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL}/v1/auth/sign-up`,
        method: "post",
        data: {
          ...data,
          app: "accounts",
        },
      })
        .then((res) => {
          if (res.status === 203) {
            setAppModal({
              title: "Username Taken",
              children:
                "This username has already been registered by someone else",
            });
            reject(null);
            return;
          }
          const response = res.data as SignUpResponse;
          const { authToken, ...userData } = response;
          saveUser(userData);
          saveToken(authToken.token);
          resolve(response);
        })
        .catch((error) => {
          reject(error as ErrorResponse);
        })
        .finally(() => {
          setAppLoading(false);
        });
    });
  };

  /***************************************/
  /** Resend Verification Code Via Email */
  const resendVerificationCode = (data: SendVerificationData) => {
    return new Promise<void>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL}/v2/auth/resend-verification-code`,
        method: "post",
        data,
      })
        .then((res) => {
          let message = res.data.details[0];
          if (!(message && typeof message === "string")) {
            message = "A verification code has been sent to your email!";
          }
          setAppModal({
            title: "Verification Code Sent",
            children: message,
          });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /***************************/
  /** Request Password Reset */
  const requestPasswordReset = (data: RequestPasswordResetData) => {
    setAppLoading(true);
    return new Promise<void>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL}/v2/auth/request-password-reset`,
        method: "post",
        data,
      })
        .then((res) => {
          let message = res.data.details[0];
          if (!(message && typeof message === "string")) {
            message = "A verification code has been sent to your email!";
          }
          setAppModal({
            title: "Verification Code Sent",
            children: message,
          });
          resolve();
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setAppLoading(false);
        });
    });
  };

  /*******************/
  /** Password Reset */
  const resetPassword = (data: ResetPasswordData) => {
    return new Promise<any>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL}/v2/auth/reset-password`,
        method: "post",
        data,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /***********************************/
  /** Logout user and delete session */
  const logOut = () => {
    return new Promise<Date>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL}/v2/auth/logout`,
        method: "delete",
      })
        .then((res) => {
          resolve(res.data.details as Date);
        })
        .catch((error) => {
          reject(error);
          closeModal();
        })
        .finally(() => {
          localStorage.setItem("logout", Date.now().toString());
          clearToken();
          setAppLoading(false);
        });
    });
  };

  /************************/
  /** Get the server's time
   * Used for date and time comparisons */
  const getTime = () => {
    return new Promise<Date>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL}/v1/time`,
      })
        .then((res) => {
          resolve(res.data.details as Date);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    signIn,
    signUp,
    resendVerificationCode,
    requestPasswordReset,
    resetPassword,
    logOut,
    getTime,
  };
};

export default useAuth;
