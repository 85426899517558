import { Link } from "react-router-dom";

import icon_404 from "../../assets/vector-images/panel/404.svg";
import { InfoPanel } from "../../components/layout";
import { rootPaths } from "../../routes/paths";
import Styles from "./styles";



function Page404() {
  return (
    <Styles className="root-page">
      <div className="root-content">
        <div className="page-wrapper centered">
          <InfoPanel
            icon={icon_404}
            title="Page Not Found"
            description={
              <div className="flex column">
                <div>The Page you're looking for was not found.</div>
                <div>Let's get you back home</div>
              </div>
            }
          >
            <Link
              className="button"
              to={rootPaths.HOMEPAGE}
            >
              Go Home
            </Link>
          </InfoPanel>
        </div>
      </div>
    </Styles>
  );
}

export default Page404;