import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import check_mark from "../../../assets/vector-images/generic/check-2.svg";
import { UserMission } from "../../../requests/missions/types";
import { missionPaths } from "../../../routes/missions/paths";
import { getTreeStateUrl } from "../../../constants/core";
import { UserTree } from "../../../requests/trees/types";
import useMissions from "../../../requests/missions";
import { Loader } from "../../../components/layout";
import useMissionListUtils from "./utils";
import Styles from "./styles";



type Props = {
  desertId: number | string;
  treeId: number | string;
}
function MissionList({
  desertId,
  treeId
}: Props) {

  const { getLoadMessage } = useMissionListUtils();

  const { listMissions } = useMissions();
  const [loading, setLoading] = useState<boolean>(true);
  const [userTree, setUserTree] = useState<UserTree>();
  const [userMissions, setUserMissions] = useState<UserMission[]>([]);


  const retrieveMissions = (treeId: string | number) => {
    const params = { treeId };
    listMissions(params)
      .then((resData) => {
        setUserTree(resData.userTree);
        setUserMissions(resData.userMissions)
      })
      .catch(() => null)
      .finally(() => {
        setLoading(false);
      });
  }


  useEffect(() => {
    retrieveMissions(treeId);
  }, [treeId])



  return (
    (loading || !userTree) ? (
      <Loader message={getLoadMessage()} />
    ) : (
      <Styles className="user-missions">
        {/* Mission Progress Bar */}
        <div className="mission-progress-bar">
          <div className="tree">
            <div className="fill" />
          </div>
          <div className="branches flex column">
            {[...Array(userMissions.length)].map((_, index) =>
              <div
                key={userMissions[index].id}
                className="branch"
                style={{
                  top: `calc(270px * ${index + 1})`
                }}
              >
                <div className="fill" />
              </div>
            )}
          </div>
        </div>

        {/* Tree Environment State */}
        <section className="tree-state">
          <img src={getTreeStateUrl(userTree).forestViewUrl} alt="" />
        </section>

        {/* Set the branch on the user mission
         
          Then set the fill based on the user mission's completion level
          Create a missions/desert page. Then nest mission list under that 
          */}

        {/* */}
        {/* User Missions */}
        <section className="user-missions">
          {userMissions.map((userMission) => {
            const completed = (
              userMission.mission.tokens ===
              userMission.highestEarnedTokens
            );
            return (
              <Link
                key={userMission.id}
                to={missionPaths.missionLesson(desertId, userMission.id)}
                className={
                  `flex column user-mission ${completed ? "completed" : ""}`
                }
              >
                <div
                  className={
                    `cover-image ${userMission.locked ? "locked" : ""}`
                  }
                >
                  <img src={userMission.mission.thumbnailUrl} alt="" />
                </div>
                <div className="details">
                  <div className="title">
                    {userMission.mission.title}
                    <div className="flex align-center tokens">
                      <img
                        src={completed ? check_mark : check_mark}
                        alt=""
                      />
                      <div className={`value ${completed ? "completed" : ""}`}
                      >
                        {userMission.highestEarnedTokens
                        } / {userMission.mission.tokens} TOKENS EARNED
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            )
          }

          )}
        </section>
      </Styles>
    )
  );
}

export default MissionList;