import { SelectFieldOption } from "../components/forms/SelectField";
import { FamilyRole } from "../requests/users/types";



type FamilyRoleSelect = SelectFieldOption & {
  value: FamilyRole;
}
export const familyRoles: FamilyRoleSelect[] = [
  {
    value: "admin",
    label: "Parent"
  },
  {
    value: "member",
    label: "Learner"
  }
]


const mixedRelationships = [
  "brother",
  "sister",
  "friend",
  "cousin",
  "niece",
  "nephew"
]

const parentRelationships: string[] = [
  "mother",
  "father",
  "uncle",
  "auntie",
  "grandfather",
  "grandmother",
  "husband",
  "wife",
  "spouse",
  ...mixedRelationships
]

const childRelationships: string[] = [
  "son",
  "daughter",
  ...mixedRelationships
]

const allRelationships: string[] = [
  ...parentRelationships,
  ...childRelationships,
  ...mixedRelationships
]

export const familyRelationships = (familyRole: FamilyRole = "admin") => {
  let relationships = [];
  switch (familyRole) {
    case "admin":
      relationships = parentRelationships;
      break;
    case "member":
      relationships = childRelationships;
      break;
    default:
      relationships = allRelationships;
      break;
  }
  const relationshipOptions = relationships.map(value => ({
    value,
    label: value.charAt(0).toUpperCase() + value.slice(1)
  }));

  return relationshipOptions
}


export const getVerboseFamilyRole = (type: FamilyRole) => {
  switch (type) {
    case "admin":
      return "Parent";
    case "member":
      return "Learner";
    default:
      return "Parent";
  }
}

export const UserFilters: SelectFieldOption[] = [
  {
    value: "",
    label: "All Users"
  },
  ...familyRoles,
]