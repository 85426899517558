import { subscriptionPaths } from "./subscriptions/paths";
import { profileSetupPaths } from "./profileSetup/paths";
import Subscriptions from "../pages/Subscriptions";
import { downloadPaths } from "./download/paths";
import { securityPaths } from "./security/paths";
import ProfileSetup from "../pages/ProfileSetup";
import { consolePaths } from "./_console/paths";
import { missionPaths } from "./missions/paths";
import { familyPaths } from "./family/paths";
import { walletPaths } from "./wallet/paths";
import Download from "../pages/Download";
import Security from "../pages/Security";
import Missions from "../pages/Missions";
import Console from "../pages/_console";
import Wallet from "../pages/Wallet";
import Family from "../pages/Family";
import { rootPaths } from "./paths";
import { RouteType } from "./types";



/***************/
/* ROOT ROUTES */
export const rootRoutes: RouteType[] = [
  {
    path: rootPaths.SECURITY,
    element: <Security />,
    redirect: securityPaths.VERIFY_EMAIL
  },
  {
    path: rootPaths.SUBSCRIPTIONS,
    element: <Subscriptions />,
    redirect: subscriptionPaths.PLAN_LIST
  },
  {
    path: rootPaths.PROFILE_SETUP,
    element: <ProfileSetup />,
    redirect: profileSetupPaths.COUNTRY_ENTRY
  },
  {
    path: rootPaths.DOWNLOAD,
    element: <Download />,
    redirect: downloadPaths.DOWNLOAD_APP
  },
  {
    path: rootPaths.FAMILY,
    element: <Family />,
    redirect: familyPaths.detail()
  },
  {
    path: rootPaths.WALLET,
    element: <Wallet />,
    redirect: walletPaths.dashboard()
  },
  {
    path: rootPaths.MISSIONS,
    element: <Missions />,
    redirect: missionPaths.map()
  },
  {
    path: "/start-mission",
    element: <Missions />,
    redirect: missionPaths.ONBOARDING_LESSON
  },
  {
    path: rootPaths.CONSOLE,
    element: <Console />,
    redirect: consolePaths.DASHBOARD
  }
]