import { subscriptionRoot } from "./subscriptions/paths";
import { profileSetup } from "./profileSetup/paths";
import { downloadRoot } from "./download/paths";
import { securityRoot } from "./security/paths";
import { missionsRoot } from "./missions/paths";
import { consoleRoot } from "./_console/paths";
import { nonAuthRoot } from "./nonAuth/paths";
import { familyRoot } from "./family/paths";
import { walletRoot } from "./wallet/paths";



/**************/
/* ROOT PATHS */
export const rootPaths = {
  HOMEPAGE: "/",
  NON_AUTH: nonAuthRoot,
  CONSOLE: consoleRoot,
  SECURITY: securityRoot,
  SUBSCRIPTIONS: subscriptionRoot,
  PROFILE_SETUP: profileSetup,
  DOWNLOAD: downloadRoot,
  FAMILY: familyRoot,
  MISSIONS: missionsRoot,
  WALLET: walletRoot
}