import styled from "styled-components";
import { properties } from "../../../styles";



const Styles = styled.div`
  flex: 1;
  gap: 3rem !important;
  .progress-bar {
    max-width: ${properties.maxFieldWidth};
    margin: 0 auto;
  }
  .form-steps {
    flex: 1;
  }
`;

export default Styles;