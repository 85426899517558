import { Outlet } from "react-router-dom";

import { AuthHeader, NonAuthHeader } from "../../components/auth";
import { User } from "../../requests/users/types";
import { useAppState } from "../../redux/state";



// Context (Props) passed down to Child Pages (<Outlet/>)
export type SubscriptionsContext = {
  user?: User | null;
}

function Subscriptions() {
  const { user } = useAppState();

  return (
    <>
      {user
        ? <AuthHeader user={user} />
        : <NonAuthHeader />
      }
      <div className="root-content">
        <Outlet context={{ user }} />
      </div>
    </>
  );
}

export default Subscriptions;