import { useNavigate, useLocation, Navigate, Outlet } from "react-router-dom";
import axios from "axios";

import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import { User } from "../../../requests/users/types";
import { useAppModal } from "../../layout/AppModal/utils";
import { useAppState } from "../../../redux/state";
import { ResponseCodes } from "../../../types";
import useAuth from "../../../requests/auth";
import { RootPage, AuthHeader } from "..";
import Styles from "./styles";



export type AuthRouteContext = {
  user: User;
}

function AuthRoute() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setAppModal } = useAppModal();
  const { user, token } = useAppState();
  const { logOut } = useAuth();


  /***************************************************************/
  /** Append these headers to authenticate all outgoing requests */
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Token ${token}`;
  }

  /* ALL AUTHENTICATED NETWORK RESPONSES */
  axios.interceptors.response.use(
    (response: any) => response,
    (error) => {
      if (error && error.response) {
        const { status } = error.response;
        if (status === ResponseCodes.Unauthorized) {
          logOut()
            .catch(() => null)
            .finally(() => {
              navigate(nonAuthPaths.SIGN_IN, {
                state: { previousPath: pathname },
              });
            });
        } else if (status === ResponseCodes.Forbidden) {
          setAppModal({
            title: "There's an Issue",
            children: "Unfortunately you are not authorized to view this page",
          });
        }
      }
      return Promise.reject(error);
    }
  );



  return (
    <RootPage>
      {token && user ? (
        <Styles className="root-page">
          {/* Header */}
          <AuthHeader user={user} />
          {/* Page Content */}
          <Outlet context={{ user }} />
        </Styles>
      ) : (
        <Navigate
          to={nonAuthPaths.SIGN_IN}
          state={{ previousPath: pathname }}
          replace
        />
      )}
    </RootPage>
  );
}

export default AuthRoute;