import styled from "styled-components";

import { colors, functions } from "../../../styles";



const Styles = styled.div`
  .plan {
    padding: 1.2rem 1rem;
    background-color: ${functions.rgba(colors.primary, 0.1)};
    border: 1px solid ${colors.primary};
    border-radius: 10px;
    font-weight: 700;
    margin-bottom: 1rem;
    color: ${colors.grey3};
  }
  .price {
    &.old {
      color: ${colors.grey6};
      text-decoration: line-through;
    }
  }
`;

export default Styles;