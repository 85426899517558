import { Link } from "react-router-dom";

import succes from "../../../assets/vector-images/panel/success.svg";
import ananse_happy from
  "../../../assets/vector-images/ananse/bust_smile_staff_no glow.svg";
import ananse_sad from
  "../../../assets/vector-images/ananse/bust_sad_stafff_no glow.svg";
import error from "../../../assets/vector-images/panel/error.svg";
import { MissionResult } from "../../../requests/missions/types";
import { missionPaths } from "../../../routes/missions/paths";



type Props = {
  missionResult: MissionResult;
}

function QuizResults({
  missionResult
}: Props) {
  const { mission } = missionResult.userMission;
  const totalQuizzes = missionResult.quizResults.length;
  const correctQuizzes = missionResult.quizResults.filter((quizResult) =>
    quizResult.option.isCorrect
  ).length;
  const passed = (correctQuizzes / totalQuizzes) >= 0.5;



  return (
    <div id="Result">
      <header className="flex column center">
        <img src={passed ? succes : error} alt="" />
        <h1 className="title">
          {correctQuizzes.toString()}/{totalQuizzes.toString()}{" "
          }Answers Correct!
        </h1>
      </header>
      {passed ? (
        <section>
          <figure className="panel">
            <img src={ananse_happy} alt="" />
            <figcaption className="message-box passed">
              CONGRATULATIONS!
              <br />
              Now on to the next!
            </figcaption>
          </figure>
          <Link
            to={missionPaths.desertMissions(mission.tree.desertId)}
            className="button mt-2"
          >
            Awesome!
          </Link>
        </section>
      ) : (
        <section>
          <figure className="panel">
            <img src={ananse_sad} alt="" />
            <figcaption className="message-box failed">
              So close!
              <br />
              Let's try one more time.
            </figcaption>
          </figure>
          <Link
            to={missionPaths.missionLesson(
              mission.tree.desertId,
              missionResult.userMission.id
            )}
            className="button white-black mt-2"
          >
            Retry Mission
          </Link>
        </section>
      )}
    </div>
  );
}

export default QuizResults;