import { subscriptionPaths } from "./paths";
import {
  Checkout,
  PlanList,
  SubscriptionFailed,
  SubscriptionSuccess,
  VoucherEntry
} from "../../pages/Subscriptions/pages";
import { RouteType } from "../types";



/************************/
/** Subscription Routes */
export const subscriptionRoutes: RouteType[] = [
  {
    path: subscriptionPaths.PLAN_LIST,
    element: <PlanList />
  },
  {
    path: subscriptionPaths.SUCCESS,
    element: <SubscriptionSuccess />
  },
  {
    path: subscriptionPaths.FAILED,
    element: <SubscriptionFailed />
  },
  {
    path: subscriptionPaths.CHECKOUT,
    element: <Checkout />
  },
  {
    path: subscriptionPaths.VOUCHER_ENTRY,
    element: <VoucherEntry />
  }
]