import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";

import { FormField, FormValues } from "../../../components/forms/Form/types";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import Form from "../../../components/forms/Form";
import useAuth from "../../../requests/auth";
import Styles from "./styles";



function ForgotPassword() {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [formValues, setFormValues] = useState<FormValues>({});
  const { requestPasswordReset } = useAuth();


  /****************/
  /** Submit Data */
  const submitData = () => {
    const username = formValues.username;
    requestPasswordReset({ username })
      .then(() => {
        const encodedUsername = encodeURIComponent(username);
        navigate(`${nonAuthPaths.RESET_PASSWORD
          }?username=${encodedUsername}`);
      }).catch(() => null);
  }

  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "email",
      name: "username",
      label: "Enter your email",
      placeholder: "you@example.com",
      autoComplete: "email",
      groupClass: "field-group"
    }
  ]



  return (
    <Styles className="root-content bg-action-pose">
      <div className="page-wrapper field-width centered">
        {/* Heading */}
        <header>
          <h3 className="title">
            Forgot your Password?
          </h3>
          <div className="description">
            Enter your email to get a code to reset your account password.
            <br />
            If you have a Learner account, ask your Parent / Family Admin{" "}
            to reset your password for you
          </div>
        </header>
        {/* Forgot Password Form */}
        <section>
          <Form
            fields={formFields}
            values={formValues}
            setValues={setFormValues}
            onSubmit={submitData}
            ref={formRef}
          >
            <button
              className="button submit full-width"
              type="submit"
            >
              Send Verification Code
            </button>
          </Form>
        </section>
      </div>
    </Styles>
  );
}

export default ForgotPassword;