import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { getActiveDiscount } from "../../../requests/subscriptions/utils";
import { subscriptionPaths } from "../../../routes/subscriptions/paths";
import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import success from "../../../assets/vector-images/panel/success.svg";
import { securityPaths } from "../../../routes/security/paths";
import { InfoPanel, Loader } from "../../../components/layout";
import useSubscriptions from "../../../requests/subscriptions";
import { consolePaths } from "../../../routes/_console/paths";
import { useQueryParams } from "../../../utils/components";
import { getPlanInterval } from "../../../utils/payments";
import { currencySymbol } from "../../../utils/strings";
import { decimalPrice } from "../../../utils/numbers";
import { User } from "../../../requests/users/types";
import {
  getDiscountedPrice
} from "../../../requests/planPriceDiscounts/utils";
import {
  SubscriptionTxn, SubscriptionTxnStatus
} from "../../../requests/subscriptions/types";
import useUsers from "../../../requests/users";
import Styles from "./styles";



function SubscriptionSuccess() {
  const navigate = useNavigate();
  const { queryParams } = useQueryParams();
  const paymentId = queryParams.get("payment_id") || "";
  const { getUpdatedPaymentStatus } = useSubscriptions();
  const { getUserProfile } = useUsers();
  const [user, setUser] = useState<User>();
  const [subscriptionTxn, setSubscriptionTxn] = useState<SubscriptionTxn>();
  const [discountedPrice, setDiscountedPrice] = useState<number | null>(null);


  /**************************/
  /** Get Transaction Info */
  const onPageLoad = async (txnId: string) => {
    try {
      const user = await getUserProfile();
      setUser(user);
      const txn = await getUpdatedPaymentStatus(txnId);
      const successStates: SubscriptionTxnStatus[] = ["successful", "open"];
      if (successStates.includes(txn.status)) {
        setSubscriptionTxn(txn);
        const discount = getActiveDiscount(txn.subscription)
        if (discount) {
          const discountedAmount = getDiscountedPrice(
            txn.desiredPlanPrice.amount,
            discount.planPriceDiscount.percentage
          );
          setDiscountedPrice(discountedAmount);
        }
      } else {
        navigate(subscriptionPaths.FAILED);
      }
    } catch { }
  }


  useEffect(() => {
    if (!paymentId) return;
    onPageLoad(paymentId);
  }, [paymentId])



  return (
    subscriptionTxn ? (
      <Styles className="root-content">
        <div className="page-wrapper flex column center">
          <InfoPanel
            icon={success}
            title="Subscription Successful"
            description={(
              <div className="flex column">
                <div className="flex justify-between align-center plan">
                  <div>
                    {subscriptionTxn.desiredPlanPrice.plan.name} Plan - {" "}
                    {getPlanInterval(subscriptionTxn.desiredPlanPrice.interval)}
                  </div>
                  <div className="prices flex center">
                    <div className={`price ${discountedPrice ? "old" : ""}`}>
                      {currencySymbol(subscriptionTxn.desiredPlanPrice.currency)}
                      {decimalPrice(subscriptionTxn.desiredPlanPrice.amount)}
                    </div>
                    {discountedPrice ? (
                      <div className="price">
                        {currencySymbol(subscriptionTxn.desiredPlanPrice.currency)}
                        {decimalPrice(discountedPrice)}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="message">
                  You've successfully signed up for this plan!
                </div>
              </div>
            )}
          >
            {user && user.completedOnboarding ? (
              <Link
                className="button"
                to={consolePaths.DASHBOARD}
              >
                Go to your Dashboard
              </Link>
            ) : (
              <Link
                className="button"
                to={user && !user.emailVerified
                  ? securityPaths.VERIFY_EMAIL
                  : profileSetupPaths.NAME_ENTRY
                }
              >
                Setup Profile
              </Link>
            )}
          </InfoPanel>
        </div>
      </Styles>
    ) : (
      <Loader message="Getting your Subscription ..." />
    )
  );
}

export default SubscriptionSuccess;
