import { Outlet, useOutletContext } from "react-router-dom";

import { ConsoleContext } from "..";



export type SubscriptionContext = ConsoleContext;
function Subscription() {
  const consoleContext = useOutletContext<SubscriptionContext>();

  return (
    <Outlet context={consoleContext} />
  );
}

export default Subscription;