import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { FormValues } from "../../../components/forms/Form/types";
import { MissionQuizzes } from "../../../components/missions";
import { missionPaths } from "../../../routes/missions/paths";
import { Quiz } from "../../../requests/missions/types";
import useMissions from "../../../requests/missions";
import { Loader } from "../../../components/layout";



function OnboardingQuizzes() {
  const navigate = useNavigate();
  const { getOnboardingMission } = useMissions();
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [formValues, setFormValues] = useState<FormValues>({});


  /***************************/
  /** Get Onboarding Mission */
  const retrieveOnboardingMission = () => {
    getOnboardingMission()
      .then((mission) => {
        setQuizzes(mission.quizzes);
      }).catch(() => null);
  }

  /*******************/
  /** Submit Mission */
  const submitMission = () => {
    let correctAnswers = 0;
    quizzes.forEach((quiz) => {
      const correctAns = quiz.options.find(opt => opt.isCorrect);
      if (!correctAns) return;
      if (parseInt(formValues[quiz.id]) === correctAns.id) {
        correctAnswers++;
      }
    });
    navigate(`${missionPaths.ONBOARDING_RESULT
      }?correct=${correctAnswers}`);
  }


  useEffect(() => {
    retrieveOnboardingMission();
  }, [])



  return (
    <div className="root-content">
      <div className="page-wrapper">
        {quizzes.length ? (
          <MissionQuizzes
            quizzes={quizzes}
            lessonLink={missionPaths.ONBOARDING_LESSON}
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={submitMission}
          />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

export default OnboardingQuizzes;

