import { getRootPath } from "../utils";



export const walletRoot = "/wallet";
const learnerWallet = `${walletRoot}/users`;

/*****************/
/** Wallet Paths */
export const walletPaths = {
  getStarted: (pathname: string = "") =>
    `${getRootPath(pathname, walletRoot)
    }${walletRoot}/get-started`,
  setupMyWallet: (pathname: string = "") =>
    `${getRootPath(pathname, walletRoot)
    }${walletRoot}/setup`,
  setupSuccess: (pathname: string = "") =>
    `${getRootPath(pathname, walletRoot)
    }${walletRoot}/setup-success`,
  dashboard: (pathname: string = "") =>
    `${getRootPath(pathname, walletRoot)
    }${walletRoot}/`,
  myTxns: (pathname: string = "") =>
    `${getRootPath(pathname, walletRoot)
    }${walletRoot}/my-transactions`,

  // Learners
  LEARNER_WALLET: `${learnerWallet}/:userId`,
  learnerWallet: (userId: string, pathname: string = "") =>
    `${getRootPath(pathname, walletRoot)
    }${learnerWallet}/${userId}`,
  SETUP_LEARNER_WALLET: `${learnerWallet}/:userId/setup`,
  setupLearnerWallet: (userId: string, pathname: string = "") =>
    `${getRootPath(pathname, walletRoot)
    }${learnerWallet}/${userId}/setup`,
  LEARNER_CARD: `${learnerWallet}/:userId/card`,
  learnerCard: (userId: string, pathname: string = "") =>
    `${getRootPath(pathname, walletRoot)
    }${learnerWallet}/${userId}/card`,
  LEARNER_MILESTONES: `${learnerWallet}/milestones`,
  LEARNER_TOP_UP_REQUESTS: `${learnerWallet}/top-up-requests`,
  LEARNER_TXNS: `${learnerWallet}/:userId/transactions/`,
  learnerTxns: (userId: string, pathname: string = "") =>
    `${getRootPath(pathname, walletRoot)
    }${learnerWallet}/${userId}/transactions`,
}