import { commaDelimited } from "../../../utils/numbers";
import Styles from "./styles";



type Props = {
  total: number;
  value: number;
  description?: string;
  color?: string;
}

function ProgressBar({
  total, value, description, color
}: Props) {
  return (
    <Styles color={color} className="progress-bar">
      <div className="bar">
        <div
          className="fill"
          style={{
            width: `${(value / total) * 100}%`
          }}
        />
      </div>
      <div className="description">
        {description
          ? description
          : commaDelimited(value)
        }
      </div>
    </Styles >
  );
}

export default ProgressBar;