import { useNavigate } from "react-router-dom";

import { useAppModal } from "../../components/layout/AppModal/utils";
import { subscriptionPaths } from "../../routes/subscriptions/paths";
import useAppActions from "../../redux/actions/app";
import { PlanPriceInterval } from "../plans/types";
import {
  ActiveSubscription, DowngradeCheckArgs,
  SubscribeToPlanArgs, Subscription,
  SubscriptionStatus
} from "./types";
import useSubscriptions from ".";



const useSubscriptionUtils = () => {
  const navigate = useNavigate();
  const { setAppModal, closeModal } = useAppModal();
  const { startSubscription } = useSubscriptions();
  const { setAppLoading } = useAppActions();


  /***************************/
  /** Downgrade confirmation */
  const confirmDowngrade = (
    planName: string
  ) => {
    return new Promise<boolean>((resolve, reject) => {
      setAppModal({
        title: "Downgrade Plan?",
        children: (
          `Are you sure you want to downgrade to the ${planName} Plan?`
        ),
        controls: (
          <div className="modal-controls">
            <button
              className="button full-width white-primary"
              onClick={() => {
                closeModal();
                resolve(true);
              }}
            >
              Yes, I'll downgrade
            </button>
            <button
              className="button full-width"
              onClick={() => {
                closeModal();
                reject(false);
              }}
            >
              No, keep my current plan
            </button>
          </div>
        )
      });
    });
  }

  /*****************************/
  /** Inform user of downgrade */
  const downgradeCheck = ({
    plan,
    activeSubscription,
  }: DowngradeCheckArgs) => {
    if (
      plan.id < activeSubscription.planPrice.planId &&
      plan.name !== activeSubscription.planPrice.plan.name
    ) {
      return confirmDowngrade(plan.name);
    }
    return Promise.resolve();
  }

  /*************************************/
  /** Get payment URL and pay for Plan */
  const subscribeToPlan = async ({
    plan,
    planPrice,
    activeSubscription,
    discountCode
  }: SubscribeToPlanArgs) => {
    const proceedWithSubscription = async () => {
      setAppLoading(true);
      const data = {
        planPriceId: planPrice.id,
        ...(discountCode ? {
          discountCode
        } : {})
      };
      try {
        const response = await startSubscription(data);
        if (response.paymentUrl) {
          window.location.replace(response.paymentUrl);
        } else if (response.payment_id) {
          navigate(`${subscriptionPaths.SUCCESS
            }?payment_id=${response.payment_id}`,
            { replace: true }
          );
        }
      } catch {
        // window.location.reload();
      } finally {
        setAppLoading(false);
      }
    }
    if (activeSubscription) {
      downgradeCheck({
        plan,
        planPrice,
        activeSubscription,
      }).then(async () => {
        await proceedWithSubscription()
      }).catch(() => null);
    } else {
      proceedWithSubscription(); // No current subscription, purchase
    }
  }


  return {
    subscribeToPlan
  }
}

export default useSubscriptionUtils;


/*********************************************/
/** Check if user has an active subscription */
export const hasActiveSubscription = (
  subscription: Subscription | null
): ActiveSubscription | null => {
  const expiredStates: SubscriptionStatus[] = ["expired", "cancelled"];
  if (
    subscription &&
    subscription.planPrice &&
    !expiredStates.includes(subscription.status)
  ) {
    return subscription as ActiveSubscription;
  } else {
    return null;
  }
}


export const getVerboseInterval = (interval: PlanPriceInterval) => {
  switch (interval) {
    case "month":
      return "Monthly";
    case "year":
      return "Annually";
    default:
      return "Monthly";
  }
}


/********************************/
/** Get and Set Active Discount */
export const getActiveDiscount = (subscription?: Subscription | null) => {
  if (!subscription) return null;
  const discount = subscription.discounts.find(
    discount => (
      (discount.active) &&
      (discount.planPriceDiscount.planPriceId === subscription.planPriceId)
    )
  );
  if (discount) return discount;
  else return null;
}
