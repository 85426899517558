import { MouseEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getName } from "country-list";


import delete_icon from "../../../assets/vector-images/generic/delete.svg";
import space from "../../../assets/vector-images/generic/space.svg";
import { UpdateProfileArgs } from "../../../requests/users/types";
import { consolePaths } from "../../../routes/_console/paths";
import { familyPaths } from "../../../routes/family/paths";
import useHeritages from "../../../requests/heritages";
import { getFlagEmoji } from "../../../utils/images";
import useUsers from "../../../requests/users";
import { ProfileSetupContext } from "..";
import HeritagePicker from "./Picker";
import Styles from "./styles";



function Heritages() {
  const navigate = useNavigate();
  const { updateProfile } = useUsers();
  const { listUserHeritages } = useHeritages();
  const { user } = useOutletContext<ProfileSetupContext>();
  const [heritages, setHeritages] = useState<string[]>([]);
  const [pickerOpen, setPickerOpen] = useState<boolean>(false);


  /*******************/
  /** Save Heritages */
  const saveHeritages = (e: MouseEvent) => {
    e.preventDefault();
    const profileData: UpdateProfileArgs = {
      heritages
    }
    updateProfile(profileData)
      .then(() => {
        if (user.familyRole === "admin")
          navigate(familyPaths.setup(""));
        else
          navigate(consolePaths.DASHBOARD);
      }).catch(() => null);
  }

  /*************************/
  /** Add Heritage to List */
  const addHeritage = (e: MouseEvent, heritage: string) => {
    e.preventDefault();
    // Remove duplicates before updating heritage list
    const currentHeritages = heritages.filter(h => h !== heritage);
    setHeritages([
      ...currentHeritages,
      heritage
    ]);
  }

  /******************************/
  /** Remove Heritage from List */
  const removeHeritage = (e: MouseEvent, heritage: string) => {
    e.preventDefault();
    const currentHeritages = heritages.filter(h => h !== heritage);
    setHeritages(currentHeritages);
  }

  /*********************************/
  /** Get previously set Heritages */
  const getHeritages = useCallback(() => {
    listUserHeritages()
      .then((res) => {
        setHeritages(res.results.map(h => h.heritage));
      }).catch(() => null);
  }, [])


  useEffect(() => {
    getHeritages()
  }, [getHeritages])



  return (
    <Styles className="page-wrapper">
      {/* Heading */}
      <header>
        <h3 className="title">
          Choose Heritage
        </h3>
        <div className="subtitle">
          Select the countries your family hails from
        </div>
      </header>

      {/* Selected Heritages */}
      {heritages.length ? (
        <div className="selected-heritages">
          <h3 className="title">
            Your Heritages
          </h3>
          <ul className="flex column">
            {heritages.map((heritage, index) => (
              <li
                key={`${heritage}-${index}`}
                className="heritage flex align-center justify-between"
              >
                <div className="country flex">
                  <div className="flag">
                    {getFlagEmoji(heritage)}
                  </div>
                  <div className="name">
                    {getName(heritage)}
                  </div>
                </div>
                <button
                  className="btn-image"
                  onClick={(e) => removeHeritage(e, heritage)}
                >
                  <img src={delete_icon} alt="" />
                </button>
              </li>
            ))}
            <button
              className="button full-width submit"
              onClick={saveHeritages}
            >
              Save to My Profile
            </button>
          </ul>
        </div>
      ) : null}
      {/* Open Heritage Selection Modal */}
      <button
        className="choose-heritage"
        onClick={() => setPickerOpen(true)}
      >
        <figure className="flex align-center">
          <img src={space} alt="" />
          <figcaption>
            <div>Tap to Choose or Add to Your Heritage</div>
          </figcaption>
        </figure>
      </button>

      {/* Country Picker Modal */}
      <HeritagePicker
        pickerOpen={pickerOpen}
        setPickerOpen={setPickerOpen}
        addHeritage={addHeritage}
      />
    </Styles>
  );
}

export default Heritages;