import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";

import { Loader, PlanDetail, Spinner } from "../../../components/layout";
import { subscriptionPaths } from "../../../routes/subscriptions/paths";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import { useQueryParams } from "../../../utils/components";
import { daysToMonths } from "../../../utils/dates";
import { useAppState } from "../../../redux/state";
import { Form } from "../../../components/forms";
import { URLParams } from "../../../constants";
import { useCheckoutUtils } from "./utils";
import Styles from "./styles";



function Checkout() {
  const { planPriceId } = useParams<URLParams>();
  const { locationSearch, queryParams } = useQueryParams();
  const discountCode = queryParams.get("discountCode") || "";
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user } = useAppState();
  const formRef = useRef<HTMLFormElement>(null);
  const {
    retrievePlanPrice, checkoutPlan, getSubtotal, findDiscount,
    setFormValues, formFields, discountLoading, discount,
    planPrice, activeSubscription, formValues
  } = useCheckoutUtils();


  useEffect(() => {
    if (!planPriceId) return;
    if (!user) {
      navigate(
        nonAuthPaths.SIGN_IN,
        { state: { previousPath: pathname } }
      );
    }
    retrievePlanPrice(planPriceId);
  }, [planPriceId, user])


  useEffect(() => {
    findDiscount(discountCode);
  }, [locationSearch])



  return (
    <Styles className="page-wrapper">
      {!planPrice ? (
        <Loader />
      ) : (
        <>
          {/* Plan Price Details */}
          <PlanDetail
            plan={planPrice.plan}
            planPrice={planPrice}
            activeSubscription={activeSubscription}
            action="none"
          />

          <div className="checkout flex column">
            {/* Sub Total */}
            <div className="subtotal card">
              <h2 className="jumbo-title">
                Subtotal
              </h2>
              <div className="content">
                {/* Subtotal Calculation */}
                {getSubtotal()}

                {/* Checkout action */}
                {(
                  activeSubscription &&
                  (activeSubscription.planPriceId === planPrice.id)
                ) ? (
                  <Link
                    className="button full-width white-black choose-plan"
                    to={subscriptionPaths.planList()}
                  >
                    You're already Subscribed
                  </Link>
                ) : (
                  <button
                    className="button full-width choose-plan"
                    type="button"
                    onClick={(e) => checkoutPlan(
                      e, planPrice, planPrice.plan, discount?.code
                    )}
                  >
                    Checkout
                  </button>
                )}
              </div>
            </div>

            {/* Discount */}
            <div className="discount-form">
              <h2 className="jumbo-title">
                Have a Discount Code?
              </h2>
              <Form
                fields={formFields}
                values={formValues}
                setValues={setFormValues}
                onSubmit={() => findDiscount()}
                ref={formRef}
              >
                {discountLoading ? (
                  <div className="mlr-auto">
                    <Spinner />
                  </div>
                ) : (
                  <button
                    className="button mlr-auto white-black"
                    type="submit"
                  >
                    Find Discount
                  </button>
                )}
              </Form>
            </div>

            {discount ? (
              <div className="applied-discounts">
                <h2 className="jumbo-title">
                  Applied Discount
                </h2>
                <div className="discount">
                  <h3 className="title">
                    {discount.code}
                  </h3>
                  <div className="description flex center">
                    <h4>{discount.percentage}%</h4> off for{" "
                    }{daysToMonths(discount.daysDuration)}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}
    </Styles>
  );
}

export default Checkout;