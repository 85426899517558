import { Region, UserTree } from "../requests/trees/types";



export const getVerboseRegionName = (region: Region) => {
  switch (region) {
    case "prehistory":
      return "Pre-History";
    case "ancient":
      return "Ancient";
    case "medieval":
      return "Medieval";
    case "colonial":
      return "Colonial";
    case "modern":
      return "Modern";
    default:
      return "Pre-History";
  }
}

export type UserTreeUrls = {
  growthUrl: string;
  forestViewUrl: string;
}

export function getTreeStateUrl(userTree: UserTree): UserTreeUrls {
  const { treeState, tree } = userTree;

  switch (treeState) {
    case "Barren":
      return {
        growthUrl: tree.barrenGrowthUrl,
        forestViewUrl: tree.barrenUrl,
      };
    case "Seed":
      return {
        growthUrl: tree.seedGrowthUrl,
        forestViewUrl: tree.seedUrl,
      };
    case "Sprout":
      return {
        growthUrl: tree.sproutGrowthUrl,
        forestViewUrl: tree.sproutUrl,
      };
    case "SproutWatering":
      return {
        growthUrl: tree.sproutWateringGrowthUrl,
        forestViewUrl: tree.sproutWateringUrl,
      };
    case "Seedling":
      return {
        growthUrl: tree.seedlingGrowthUrl,
        forestViewUrl: tree.seedlingUrl,
      };
    case "SeedlingWatering1":
      return {
        growthUrl: tree.seedlingWatering1GrowthUrl,
        forestViewUrl: tree.seedlingWatering1Url,
      };
    case "SeedlingWatering2":
      return {
        growthUrl: tree.seedlingWatering2GrowthUrl,
        forestViewUrl: tree.seedlingWatering2Url,
      };
    case "Sapling":
      return {
        growthUrl: tree.saplingGrowthUrl,
        forestViewUrl: tree.saplingUrl,
      };
    case "SaplingWatering1":
      return {
        growthUrl: tree.saplingWatering1GrowthUrl,
        forestViewUrl: tree.saplingWatering1Url,
      };
    case "SaplingWatering2":
      return {
        growthUrl: tree.saplingWatering2GrowthUrl,
        forestViewUrl: tree.saplingWatering2Url,
      };
    case "YoungTree":
      return {
        growthUrl: tree.youngTreeGrowthUrl,
        forestViewUrl: tree.youngTreeUrl,
      };
    case "YoungTreeWatering1":
      return {
        growthUrl: tree.youngTreeWatering1GrowthUrl,
        forestViewUrl: tree.youngTreeWatering1Url,
      };
    case "YoungTreeWatering2":
      return {
        growthUrl: tree.youngTreeWatering2GrowthUrl,
        forestViewUrl: tree.youngTreeWatering2Url,
      };
    case "TreeCompleted":
      return {
        growthUrl: tree.treeCompletedGrowthUrl,
        forestViewUrl: tree.treeCompletedUrl,
      };
    default:
      return {
        growthUrl: tree.barrenGrowthUrl,
        forestViewUrl: tree.barrenUrl,
      };
  }
}