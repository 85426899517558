import { Link, useLocation } from "react-router-dom";
import { MouseEvent } from "react";
import moment from "moment";

import user_icon from "../../../assets/vector-images/generic/user-icon.svg";
import delete_icon from "../../../assets/vector-images/generic/delete.svg";
import sync_icon from "../../../assets/vector-images/generic/sync.svg";
import { useAppModal } from "../../../components/layout/AppModal/utils";
import { getVerboseFamilyRole } from "../../../constants/auth";
import { TableColumn } from "../../../components/layout/Table/types";
import edit_icon from "../../../assets/vector-images/generic/edit.svg";
import { familyPaths } from "../../../routes/family/paths";
import useAppActions from "../../../redux/actions/app";
import { User } from "../../../requests/users/types";
import useFamilies from "../../../requests/families";



const useFamilyDetailUtils = (loggedInUser: User) => {
  const { pathname } = useLocation();
  const { removeUserInFamily, sendAdminInvite } = useFamilies();
  const { setAppModal, closeModal } = useAppModal();
  const { setAppLoading } = useAppActions();


  /****************************/
  /** Remove User from Family */
  const removeUser = (e: MouseEvent, user: User) => {
    e.preventDefault();
    const name = user.firstName ? (
      `${user.firstName} ${user.lastName}`
    ) : "The user";
    closeModal();
    setAppLoading(`Removing ${name} ...`);
    removeUserInFamily(user.id)
      .then(() => {
        setAppModal({
          title: "User Removed",
          children: (
            `${name} has successfully been removed from your Family!`
          ),
          cancelCallback: () => {
            window.location.reload()
          }
        });
      })
      .catch(() => null)
      .finally(() => {
        setAppLoading(false);
      });
  }

  /*********************************************/
  /** Confirm before removing user from family */
  const removeUserConfirmation = (e: MouseEvent, user: User) => {
    e.preventDefault();
    const name = user.firstName ? (
      `${user.firstName} ${user.lastName}`
    ) : "this user";
    setAppModal({
      title: "Remove Family Member",
      children: (
        <div className="modal-content">
          If you remove this member, they would no longer{" "
          }be part of this Family.
          <br /><br />
          They would also have to purchase their own Subscription{" "
          }to use the SONA App.
          <br /><br />
          <div className="bold">
            Are you sure you want to remove {name}?
          </div>
        </div>
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button white-black"
            onClick={() => {
              closeModal();
            }}
          >
            No, Cancel
          </button>
          <button
            className="button error"
            onClick={(e) => removeUser(e, user)}
          >
            Yes, Remove
          </button>
        </div>
      )
    });
  }


  /********************************/
  /** Resend Invitation to Member */
  const resendInvitation = (e: MouseEvent, user: User) => {
    e.preventDefault();
    const name = user.firstName ? (
      `${user.firstName} ${user.lastName}`
    ) : "The user";
    closeModal();
    setAppLoading(`Sending Email to ${name} ...`);
    sendAdminInvite(user.id)
      .then(() => {
        setAppModal({
          title: "Email Sent",
          children: (
            `The invatation email has been sent to ${name}!`
          ),
          cancelCallback: () => {
            window.location.reload()
          }
        });
      })
      .catch(() => null)
      .finally(() => {
        setAppLoading(false);
      });
  }

  /**********************************************/
  /** Confirm before resending invitation email */
  const resendInviteConfirmation = (e: MouseEvent, user: User) => {
    e.preventDefault();
    const name = user.firstName ? (
      `${user.firstName} ${user.lastName}`
    ) : "this user";
    setAppModal({
      title: "Resend Invitation?",
      children: (
        <div className="modal-content">
          Do you want to resend the invitation email to{" "
          }<span className="bold">
            {name} ({user.username})
          </span>?
          <br /><br />
          This is helpful if they did not receive the previous email.
        </div>
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button white-black"
            onClick={() => {
              closeModal();
            }}
          >
            No, Cancel
          </button>
          <button
            className="button"
            onClick={(e) => resendInvitation(e, user)}
          >
            Yes, Resend Email
          </button>
        </div>
      )
    });
  }

  const UserLink = (user: User) => (
    <>
      <img
        alt=""
        src={(user.avatar)
          ? user.avatar.url
          : user_icon
        }
      />
      <div className="flex align-center">
        <div className="name">
          {user.firstName ? (
            `${user.firstName} ${user.lastName}`
          ) : (
            `${user.username}`
          )}
        </div>
        {user.id === loggedInUser.id ? (
          <div className="role generic">
            You
          </div>
        ) : null}
      </div>
    </>
  )

  /******************/
  /** Table Columns */
  const tableColumns: TableColumn[] = [
    {
      title: "Name",
      key: "firstName",
      className: "al-left",
      content: (user: User) => (
        (
          (user.id !== loggedInUser.id) &&
          (user.familyRole === "member")
        ) ? (
          <Link
            to={familyPaths.editUser(user.id, pathname)}
            className="table-title"
          >
            <UserLink {...user} />
          </Link>
        ) : (
          <div className="table-title">
            <UserLink {...user} />
          </div>
        )
      )
    },
    {
      title: "Permissions",
      key: "familyRole",
      content: (user: User) => (
        <div className={`role ${user.familyRole}`}>
          {getVerboseFamilyRole(user.familyRole)}
        </div>
      )
    },
    {
      title: "Last Active",
      key: "lastActive",
      content: (user: User) => (
        <div className="grey">
          {user.lastLogin ? (
            moment(user.lastLogin).format("Do MMM YYYY, hh:mma")
          ) : "Has not signed-in yet"}
        </div>
      )
    }
  ]
  if (loggedInUser.familyRole === "admin") {
    tableColumns.push({
      title: "Actions",
      key: "",
      className: "al-right",
      content: (user: User) => (
        user.id !== loggedInUser.id ? (
          <div className="flex actions justify-end">
            {(user.familyRole === "admin" && !user.lastLogin) ? (
              <button
                onClick={(e) => resendInviteConfirmation(e, user)}
              >
                <img src={sync_icon} alt="" />
              </button>
            ) : null}
            {user.familyRole !== "admin" ? (
              <Link to={familyPaths.editUser(user.id, pathname)}>
                <img src={edit_icon} alt="" />
              </Link>
            ) : null}
            <button
              onClick={(e) => removeUserConfirmation(e, user)}
            >
              <img src={delete_icon} alt="" />
            </button>
          </div>
        ) : null
      )
    });
  }


  return {
    tableColumns
  }
}

export default useFamilyDetailUtils;