import { MouseEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import email_icon from "../../../assets/vector-images/panel/send-email.svg";
import { useTabVisibilityChange } from "../../../utils/components";
import { securityPaths } from "../../../routes/security/paths";
import { InfoPanel } from "../../../components/layout";
import useAppActions from "../../../redux/actions/app";
import { User } from "../../../requests/users/types";
import { rootPaths } from "../../../routes/paths";
import { isEmail } from "../../../utils/strings";
import useUsers from "../../../requests/users";
import useAuth from "../../../requests/auth";
import Styles from "./styles";



function VerifyEmail() {
  const navigate = useNavigate();
  const { getUserProfile } = useUsers();
  const { setAppLoading } = useAppActions();
  const { resendVerificationCode } = useAuth();
  const [user, setUser] = useState<User>();


  /*********************/
  /** Get User Profile */
  const fetchUserProfile = useCallback(() => {
    setAppLoading(true);
    getUserProfile()
      .then((resData) => { setUser(resData) })
      .catch(() => null)
      .finally(() => { setAppLoading(false) });
  }, []);

  /******************************/
  /** Resend Verification Email */
  const resendEmail = (e: MouseEvent) => {
    e.preventDefault();
    if (!user) return;
    setAppLoading(true);
    resendVerificationCode({
      username: user.username,
      type: "sign-up"
    }).then(() => null)
      .catch(() => null)
      .finally(() => {
        setAppLoading(false);
      });
  }

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  useEffect(() => {
    if (!user) return;
    if (!isEmail(user.username)) {
      navigate(rootPaths.CONSOLE);
      return;
    }
    if (isEmail(user.username) && user.emailVerified) {
      navigate(securityPaths.EMAIL_VERIFIED);
    }
  }, [user])

  // Check if email is verified when tab becomes visible again
  useTabVisibilityChange(fetchUserProfile);



  return (
    <Styles className="page-wrapper flex column center">
      <InfoPanel
        icon={email_icon}
        title="Verify Email Address"
        description={(
          "Click on the link sent to your email to verify your email address"
        )}
      >
        <div className="flex wrap center">
          <div>
            Didn't get an email?
          </div>
          <button
            className="link grey underline"
            onClick={resendEmail}
          >
            Re-send Verification Mail
          </button>
        </div>
      </InfoPanel>
    </Styles>
  );
}

export default VerifyEmail;