import { Link } from "react-router-dom";

import logo from "../../../assets/vector-images/brands/sona-logo.svg";
import { rootPaths } from "../../../routes/paths";
import Styles from "./styles";



function NonAuthHeader() {
  return (
    <Styles className="root-header">
      <div className="header-wrapper">
        <Link to={rootPaths.HOMEPAGE}>
          <img src={logo} alt="" id="logo" />
        </Link>
        <Link
          to={rootPaths.HOMEPAGE}
          className="button"
        >
          Get Started
        </Link>
      </div>
    </Styles>
  );
}

export default NonAuthHeader;