import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { profileSetupPaths } from "../../../../routes/profileSetup/paths";
import { FormStep } from "../../../../components/forms/FormSteps/types";
import { FormValues } from "../../../../components/forms/Form/types";
import useAppActions from "../../../../redux/actions/app";
import { Loader } from "../../../../components/layout";
import { Kyc } from "../../../../requests/kyc/types";
import { rootPaths } from "../../../../routes/paths";
import FormSteps, {
  useFormNavigation
} from "../../../../components/forms/FormSteps";
import useKyc from "../../../../requests/kyc";
import UploadDocForm from "./UploadDocForm";



function DocumentUpload() {
  const navigate = useNavigate();
  const { setAppLoading } = useAppActions();
  const { uploadDocument, getKycStatus } = useKyc();
  const {
    currentStep, FormNavigation, nextStep
  } = useFormNavigation();
  const [kyc, setKyc] = useState<Kyc>();
  const [loading, setLoading] = useState<boolean>();
  const [firstFormValues, setFirstFormValues] = useState<FormValues>({});
  const [secondFormValues, setSecondFormValues] = useState<FormValues>({});


  const onSubmit = () => {
    const formData = new FormData();
    if (firstFormValues.files && firstFormValues.files.length > 0) {
      Array.from(firstFormValues.files as FileList).forEach((file: File) => {
        formData.append("files", file);
      });
    }
    if (secondFormValues.files && secondFormValues.files.length > 0) {
      Array.from(secondFormValues.files as FileList).forEach((file: File) => {
        formData.append("files", file);
      });
    }
    if (firstFormValues.file1 && firstFormValues.file2) {
      formData.append("files", firstFormValues.file1);
      formData.append("files", firstFormValues.file2);
    }
    if (secondFormValues.file1 && secondFormValues.file2) {
      formData.append("files", secondFormValues.file1);
      formData.append("files", secondFormValues.file2);
    }
    setAppLoading(true);
    uploadDocument(formData)
      .then(() => {
        navigate(profileSetupPaths.KYC_UPLOAD_SUCCESS);
      })
      .catch(() => null)
      .finally(() => {
        setAppLoading(false)
      });
  }


  const formSteps: FormStep[] = [
    {
      title: "Identity Info",
      content: (
        <UploadDocForm
          kyc={kyc}
          documentType="identity"
          title="Kindly upload your Identity information"
          formValues={firstFormValues}
          setFormValues={setFirstFormValues}
          onSubmit={nextStep}
        >
          <FormNavigation
            previous={(
              <button
                className="button white-black previous"
                type="button"
                onClick={() => { navigate(rootPaths.HOMEPAGE) }}
              >
                Cancel
              </button>
            )}
          />
        </UploadDocForm>
      )
    },
    {
      title: "Address Info",
      content: (
        <UploadDocForm
          kyc={kyc}
          documentType="address"
          title="Kindly upload your Address information"
          formValues={secondFormValues}
          setFormValues={setSecondFormValues}
          onSubmit={onSubmit}
        >
          <FormNavigation
            previous={(
              <button
                className="button white-black previous"
                type="button"
                onClick={() => { navigate(rootPaths.HOMEPAGE) }}
              >
                Cancel
              </button>
            )}
            next={(
              <button
                className="button next"
                type="submit"
              >
                Upload Document(s)
              </button>
            )}
          />
        </UploadDocForm>
      )
    }
  ]


  useEffect(() => {
    setLoading(true);
    getKycStatus()
      .then((kyc) => {
        if (!kyc) {
          // Auto KYC not performed yet
          navigate(profileSetupPaths.NAME_ENTRY);
          return;
        } else {
          switch (kyc.status) {
            // Auto KYC not performed yet
            case "unstarted":
              navigate(profileSetupPaths.NAME_ENTRY);
              return;

            // Auto KYC was performed and passed
            case "pass":
              navigate(profileSetupPaths.KYC_VERIFICATION_SUCCESS);
              return;

            // Auto KYC was performed but failed
            case "fail":
            case "autoFail":
              setKyc(kyc);
              return;

            // Document Upload was previously performed but failed
            case "docFail":
              if (kyc.docUploadCount <= 3) {
                setKyc(kyc);
                return;
              } else {
                navigate(profileSetupPaths.KYC_COMPLETE_FAIL);
                return;
              }
            // KYC Check was previously performed and pending
            case "processing":
              navigate(profileSetupPaths.KYC_VERIFICATION_PENDING);
              return;

            case "completeFail":
              // Exhausted Doc Upload tries
              navigate(profileSetupPaths.KYC_COMPLETE_FAIL);
              return;
            default:
              setKyc(kyc);
              return;
          }
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          navigate(profileSetupPaths.KYC_COMPLETE_FAIL);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [])



  return (
    <div className="page-wrapper">
      {loading || !kyc ? (
        <Loader />
      ) : (
        <>
          {/* Heading */}
          <header>
            <h3 className="title">
              Verify Your{" "}
              {(kyc.requiredDocument === "identity")
                ? (
                  "Identity"
                ) : kyc.requiredDocument === "address"
                  ? (
                    "Address"
                  ) : (
                    "Identity and Address"
                  )
              }
            </h3>
            <div className="description">
              Acceptable documents which can be used{" "}
              <Link
                to={profileSetupPaths.KYC_UPLOAD_INSTRUCTIONS}
                className="link underline bold"
              >
                can be found here.
              </Link>
            </div>
          </header>

          {/* Document Upload Form */}
          <section>
            {kyc.requiredDocument === "addressAndIdentity" ? (
              <FormSteps
                currentStep={currentStep}
                steps={formSteps}
              />
            ) : (
              <UploadDocForm
                kyc={kyc}
                documentType={kyc.requiredDocument}
                formValues={firstFormValues}
                setFormValues={setFirstFormValues}
                onSubmit={onSubmit}
                title={kyc.requiredDocument === "address"
                  ? "Kindly upload your Address information"
                  : "Kindly upload your Identity information"
                }
              >
                <button
                  className="button mlr-auto"
                  type="submit"
                >
                  Save Details
                </button>
              </UploadDocForm>
            )}
          </section>
        </>
      )}
    </div>
  );
}

export default DocumentUpload;