import axios from "axios"

import { ErrorResponse } from "../../types";
import {
  StartSubscriptionArgs,
  StartSubscriptionResponse,
  Subscription,
  SubscriptionTxn
} from "./types";



/*  ############################## */
/** ## SUBSCRIPTION API REQUESTS # */
/*  ############################## */
const useSubscriptions = () => {
  /***********************************************/
  /** Get the subscription of the logged-in user */
  const getMySubscription = () => {
    return new Promise<Subscription | null>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/subscriptions/my-subscription/`
      }).then((res: any) => {
        const resData = res.data as Subscription | null;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /*************************/
  /** Start Subscription */
  const startSubscription = (data: StartSubscriptionArgs) => {
    return new Promise<StartSubscriptionResponse>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/subscriptions/start`,
        method: "post",
        data
      }).then((res: any) => {
        const resData = res.data as StartSubscriptionResponse;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /*************************/
  /** Start Subscription */
  const saveCard = () => {
    return new Promise<any>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/subscriptions/save-card`,
      }).then((res: any) => {
        resolve(res.data);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /************************/
  /** Cancel Subscription */
  const cancelSubscription = () => {
    return new Promise<any>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/subscriptions/cancel`,
      }).then((res: any) => {
        resolve(res.data);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  /*******************************/
  /** Get Updated Payment Status */
  const getUpdatedPaymentStatus = (txnId: string) => {
    return new Promise<SubscriptionTxn>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/subscription-txns/get-updated-payment-status/${txnId}`,
        method: "patch"
      }).then((res: any) => {
        resolve(res.data as SubscriptionTxn);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    getMySubscription,
    startSubscription,
    saveCard,
    cancelSubscription,
    getUpdatedPaymentStatus
  }
}

export default useSubscriptions;