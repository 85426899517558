import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import { FormField, FormValues } from "../../../components/forms/Form/types";
import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import { UpdateProfileArgs } from "../../../requests/users/types";
import Form from "../../../components/forms/Form";
import useUsers from "../../../requests/users";
import { ProfileSetupContext } from "..";



function NameEntry() {
  const navigate = useNavigate();
  const { updateProfile } = useUsers();
  const formRef = useRef<HTMLFormElement>(null);
  const { user } = useOutletContext<ProfileSetupContext>();
  const [formValues, setFormValues] = useState<FormValues>({});


  /**********************/
  /** Save Account Data */
  const saveData = () => {
    updateProfile(formValues as UpdateProfileArgs)
      .then(() => {
        navigate(profileSetupPaths.NICKNAME_ENTRY);
      }).catch(() => null);
  }

  const formFields: FormField[] = [
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      maxLength: 30,
      placeholder: "First Name",
      groupClass: "field-group"
    },
    {
      type: "text",
      name: "middleName",
      label: "Middle Name",
      required: false,
      maxLength: 15,
      placeholder: "Middle Name",
      groupClass: "field-group"
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      maxLength: 30,
      placeholder: "Last Name",
      groupClass: "field-group"
    }
  ]


  useEffect(() => {
    if (!user) return;
    const { firstName, middleName, lastName } = user;
    setFormValues((prevState) => ({
      ...prevState,
      firstName,
      middleName,
      lastName
    }));
  }, [user])



  return (
    <div className="page-wrapper">
      {/* Heading */}
      <header>
        <h3 className="title">
          Tell us your name
        </h3>
        <div className="subtitle">
          Kindly enter your Full Name as it appears on your government-issued ID.
          <br />
          This is required for the SONA card program.
        </div>
      </header>

      {/* Name Form */}
      <section>
        <Form
          fields={formFields}
          values={formValues}
          setValues={setFormValues}
          onSubmit={saveData}
          ref={formRef}
        >
          <button
            className="button full-width submit"
            type="submit"
          >
            Continue
          </button>
        </Form>
      </section>
    </div>
  );
}

export default NameEntry;