import { useOutletContext, Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

import { Table, Loader, FamilyCrest } from "../../../components/layout";
import { familyPaths } from "../../../routes/family/paths";
import { Family } from "../../../requests/families/types";
import useFamilies from "../../../requests/families";
import { User } from "../../../requests/users/types";
import { ConsoleContext } from "../../_console";
import useFamilyDetailUtils from "./utilts";
import Styles from "./styles";



function FamilyDetail() {
  const { pathname } = useLocation();
  const { user } = useOutletContext<ConsoleContext>();
  const { getMyFamily, listFamilyUsers } = useFamilies();
  const { tableColumns } = useFamilyDetailUtils(user);
  const [family, setFamily] = useState<Family>();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);


  useEffect(() => {
    const fetchFamilyData = async () => {
      try {
        const family = await getMyFamily();
        setFamily(family);

        const resData = await listFamilyUsers();
        setUsers(resData.results);
      } catch (error) {
        console.error("An error occurred while fetching family data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFamilyData();
  }, [])



  return (
    <Styles className="page-wrapper">
      {loading ? (
        <Loader />
      ) : family ? (
        <>
          <header>
            <div className="flex">
              <h1 className="title">
                My Family
              </h1>
            </div>
          </header>
          <div className="flex justify-between align-center wrap">
            <Link
              to={(user.familyRole === "admin")
                ? familyPaths.setup(pathname)
                : familyPaths.detail(pathname)}
            >
              <FamilyCrest family={family} />
            </Link>
            {user.familyRole === "admin" ? (
              <div className="flex column add-members">
                <Link
                  to={familyPaths.addUser(pathname)}
                  className="button sm"
                >
                  Add a new Member
                </Link>
                {/* <Link
                to={familyPaths.createInvite(pathname)}
                className="button sm white-primary"
              >
                Invite Someone
              </Link> */}
              </div>
            ) : null}
          </div>
          <div className="user-list">
            <Table
              data={users}
              columns={tableColumns}
            />
            {/* {user.familyRole === "admin" ? (
            <Link
              to={familyPaths.viewInvites(pathname)}
              className="button sm white-primary mlr-auto mt-2"
            >
              View Sent Invites
            </Link>
          ) : null} */}
          </div>
        </>
      ) : <Loader />}
    </Styles>
  );
}

export default FamilyDetail;