import { getRootPath } from "../utils";



export const missionsRoot = "/missions";

/******************/
/** Mission Paths */
export const missionPaths = {
  // Onboarding Mission
  ONBOARDING_LESSON: `/onboarding-lesson`,
  ONBOARDING_QUIZ: `/onboarding-quiz`,
  ONBOARDING_RESULT: `/onboarding-result`,

  map: (pathname: string = "") =>
    `${getRootPath(pathname, missionsRoot)}${missionsRoot}/map`,
  onboarding: (pathname: string = "") =>
    `${getRootPath(pathname, missionsRoot)}${missionsRoot}/onboarding`,
  DESERT_MISSIONS: `${missionsRoot}/desert/:desertId`,
  desertMissions: (desertId: number | string, pathname: string = "") => (
    `${getRootPath(pathname, missionsRoot)}${missionsRoot
    }/desert/${desertId}`
  ),
  MISSION_LESSON:
    `${missionsRoot}/desert/:desertId/user-mission/:userMissionId/lesson`,
  missionLesson: (
    desertId: string | number,
    userMissionId: string | number,
    pathname: string = ""
  ) => (
    `${getRootPath(pathname, missionsRoot)}${missionsRoot
    }/desert/${desertId}/user-mission/${userMissionId}/lesson`
  ),
  MISSION_QUIZ:
    `${missionsRoot}/desert/:desertId/user-mission/:userMissionId/quizzes`,
  missionQuiz: (
    desertId: string | number,
    userMissionId: string | number,
    pathname: string = ""
  ) => (
    `${getRootPath(pathname, missionsRoot)}${missionsRoot
    }/desert/${desertId}/user-mission/${userMissionId}/quizzes`
  ),
  MISSION_RESULT:
    `${missionsRoot}/desert/:desertId/user-mission/:userMissionId/result`,
  missionResult: (
    desertId: string | number,
    userMissionId: string | number,
    pathname: string = ""
  ) => (
    `${getRootPath(pathname, missionsRoot)}${missionsRoot
    }/desert/${desertId}/user-mission/${userMissionId}/result`
  ),
}